import {
  Integration,
  RequiredIntegrations,
} from "../providers/AnalyticsContext";
import { logDebug } from "./util";
import type { AnalyticsConfig, InternalPackageState } from "../types";

const pkg: InternalPackageState = {
  app: "",
  debug: false,
  devMode: null,
  disabledIntegrationList: [],
  env: "development",
  eventQueueLimit: 100,
  gitCommitId: "",
  initialized: false,
  isProduction: false,
  loadTimeLimit: 5000,
  onError: () => {},
  performance: null,
  segmentWriteKey: "",
  kafkaRestProxy: "",
};

export default pkg;

export function setPackageState(config: AnalyticsConfig) {
  pkg.app = config.app || pkg.app;
  pkg.debug = config.debug ?? pkg.debug;
  pkg.env = config.env || pkg.env;
  pkg.eventQueueLimit = config.eventQueueLimit || pkg.eventQueueLimit;
  pkg.gitCommitId = config.gitCommitId || pkg.gitCommitId;
  pkg.isProduction = config.env === "production";
  pkg.loadTimeLimit = config.loadTimeLimit || pkg.loadTimeLimit;
  pkg.onError = config.onError || pkg.onError;
  pkg.performance = config.performance || pkg.performance;
  pkg.kafkaRestProxy = config.kafkaRestProxy || pkg.kafkaRestProxy;

  if (pkg.env !== "test" && !config.segmentWriteKey) {
    throw new Error("Segment write key is required");
  }

  pkg.segmentWriteKey = config.segmentWriteKey;

  setDevMode(config.devMode);
  setDisabledIntegrationList(config.disabledIntegrationList);
  setLoadTimeLimit(config.loadTimeLimit);

  pkg.initialized = true;

  if (pkg.devMode) {
    logDebug("State:", pkg);
  }
}

/*
 * If load time is set in configuration, use the provided value. Otherwise,
 * default to 5000ms in production and 10000ms in development. Development
 * is slower to load than production and needs extra time.
 *
 * "adblock" devMode simulates the effect adblockers have on analytics'
 * loading: a timeout because `analytics.ready`'s callback is never
 * called.
 */
function setLoadTimeLimit(loadTimeLimit?: number) {
  if (!pkg.isProduction && pkg.devMode === "adblock") {
    pkg.loadTimeLimit = 0;
  } else if (typeof loadTimeLimit === "number") {
    pkg.loadTimeLimit = loadTimeLimit;
  } else if (pkg.isProduction) {
    pkg.loadTimeLimit = 5000;
  } else {
    pkg.loadTimeLimit = 10000;
  }
}

function setDisabledIntegrationList(
  disabledIntegrationList: AnalyticsConfig["disabledIntegrationList"]
) {
  if (!disabledIntegrationList) {
    return;
  }

  try {
    const keys = disabledIntegrationList.split(",");
    const integrations: { [key: string]: Integration } = Integration;

    pkg.disabledIntegrationList = Object.entries(integrations)
      .filter(([key]) => {
        if (!keys.includes(key)) {
          return false;
        }

        const integrationKey = integrations[key];

        return !RequiredIntegrations[integrationKey];
      })
      .map(([_key, integration]) => integration);
  } catch (error) {
    console.error(error);
    throw new Error("@outschool/ui-analytics received malformed configuration");
  }
}

function setDevMode(devMode: AnalyticsConfig["devMode"]) {
  if (pkg.isProduction || !devMode) {
    return;
  }

  const modes = [
    "adblock",
    "bot",
    "ca",
    "enabled",
    "eurozone",
    "gdpr",
    "kr",
    "timeout",
  ];

  if (!modes.includes(devMode.toLowerCase())) {
    throw new Error("@outschool/ui-analytics unrecognized devMode");
  }

  pkg.devMode = devMode.toLowerCase();
}
