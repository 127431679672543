import { I18nLocale, useLocale, useTranslation } from "@outschool/localization";
import { LineButton } from "@outschool/ui-components-shared";
import React from "react";

import { useOidcOnClick } from "./useOidcOnClick";

interface ContinueWithLineProps {
  isLearnerApp: boolean;
  setAuthStrategy?: (strategy: "line") => void;
  trackingName: string;
  getAnonymousId?: () => Promise<string | undefined>;
}

export default function ContinueWithLine({
  isLearnerApp,
  trackingName,
  setAuthStrategy = () => undefined,
  getAnonymousId = () => Promise.resolve(undefined),
}: ContinueWithLineProps) {
  const { t } = useTranslation("ui-auth\\src\\ContinueWithLine");
  // Line auth handler needs the locale to know which secrets to use.
  const locale = useLocale();
  const onClick = useOidcOnClick({
    getAnonymousId,
    isLearnerApp,
    setAuthStrategy,
    provider: "line",
    locale,
  });

  return (
    <LineButton onClick={onClick} trackingName={trackingName}>
      {t("Continue with LINE")}
    </LineButton>
  );
}

export function useShouldShowLine() {
  const locale = useLocale();
  return locale === I18nLocale.Ja || locale === I18nLocale.ZhTw;
}
