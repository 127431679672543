import _ from "lodash";
import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";

export interface ShoppingCartItemType {
  uid: string;
  activityUid: string;
  learnerUid: string;
  /** not required for self-paced, tutoring */
  sectionUid?: string;
  isWeeklyPayment?: Boolean;
  meetingStartTime?: Date;
  meetingEndTime?: Date;
  meetingDurationWeeks?: number;
}

interface ShoppingCartData {
  cartUid: string;
  items: Array<ShoppingCartItemType>;
}

export type ShoppingCartItemInput = Omit<ShoppingCartItemType, "uid">;

const SHOPPING_CART_KEY = "ShoppingCart.enrollmentInputs";

export function useShoppingCart() {
  const [storedData, setStoredData] = useLocalStorage<
    ShoppingCartData | undefined
  >(SHOPPING_CART_KEY, undefined);

  const addToCart = React.useCallback(
    (item: ShoppingCartItemInput) => {
      const cart = storedData
        ? _.cloneDeep(storedData)
        : { cartUid: uuidv4(), items: [] };
      cart.items.push({ ...item, uid: uuidv4() });
      setStoredData(cart);
    },
    [storedData, setStoredData]
  );

  const removeFromCart = React.useCallback(
    (uid: string) => {
      if (!storedData) {
        console.error(
          `Attempted to remove item ${uid} from cart but the cart does not exist?`
        );
        return;
      }
      const updatedCart = _.cloneDeep(storedData);
      updatedCart.items = updatedCart.items.filter(item => item.uid !== uid);
      setStoredData(updatedCart);
    },
    [storedData, setStoredData]
  );

  const updateCartItem = React.useCallback(
    (uid: string, updates: Partial<ShoppingCartItemType>) => {
      if (!storedData) {
        console.error(
          `Attempted to update item ${uid} from cart but the cart does not exist?`
        );
        return;
      }
      const updatedCart = _.cloneDeep(storedData);
      const idx = updatedCart.items.findIndex(item => item.uid === uid);
      if (idx === -1) {
        console.error(
          `Attempted to update item ${uid} from cart but the item does not exist?`
        );
        return;
      }
      const updatedItem = { ...updatedCart.items[idx], ...updates };
      updatedCart.items[idx] = updatedItem;
      setStoredData(updatedCart);
    },
    [storedData, setStoredData]
  );

  const emptyCart = React.useCallback(() => {
    setStoredData(undefined);
  }, [setStoredData]);

  return {
    cartItems: storedData,
    addToCart,
    removeFromCart,
    updateCartItem,
    emptyCart,
  };
}
