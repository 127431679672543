import { Box, Icon, Theme, Typography, styled } from "@outschool/backpack";
import { faBookmark, faClock, faMagnifyingGlass } from "@outschool/icons";
import { Trans, useTranslation } from "@outschool/localization";
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useIsMobile } from "@outschool/ui-utils";
import _ from "lodash";
import React, { PropsWithChildren, useEffect, useRef } from "react";

import { TOPIC_TAXONOMY_CATEGORY_ICONS } from "../Topics/TopicIcons";
import { getFilterDisplayString, timeRangeToString } from "./SearchFilters";
import { SearchItem } from "./SearchItem";

import type { IconDefinition } from "@outschool/icons";

//@ts-expect-error - TS is whining for some reason
const memoizeObject = _.memoize(_.identity, x => JSON.stringify(x));

function TopicIcon({ topicCategory }: { topicCategory?: string }) {
  let icon: IconDefinition;
  if (!topicCategory) {
    icon = faMagnifyingGlass;
  } else {
    icon = TOPIC_TAXONOMY_CATEGORY_ICONS[topicCategory] ?? faMagnifyingGlass;
  }

  return (
    <Icon
      fixedWidth
      icon={icon}
      sx={{
        fontSize: "24px",
        marginRight: "16px",
        paddingTop: "2px",
      }}
    />
  );
}

function useTrackSuggestions(
  track: any,
  keyword: string,
  searchSuggestions: SearchItem[],
  isOpen: boolean,
  experiments: any
) {
  const suggestionTypes =
    Object.keys(searchSuggestions).length > 1
      ? _.countBy(searchSuggestions, s => s.type)
      : null;
  const trackedSuggestions = memoizeObject(suggestionTypes);

  // don't want to track when keyword changes, just want to track what keyword is
  // when suggestions change
  const keywordRef = useRef(keyword);
  keywordRef.current = keyword;

  useEffect(() => {
    if (track && isOpen && trackedSuggestions) {
      track(
        "listings_typeahead_show",
        {
          suggestions: trackedSuggestions,
          keyword: keywordRef.current,
          experiments,
        },
        {
          // Redshift only options
          integrations: {
            All: false,
          },
        }
      );
    }
  }, [track, isOpen, trackedSuggestions, keywordRef, experiments]);
}

const StyledUnorderedList = styled("ul")({});

export function SearchSuggestionsMenu({
  getMenuProps,
  isOpen,
  searchSuggestions,
  loadingSuggestions,
  highlightedIndex,
  getItemProps,
  keyword,
  track,
  menuStyle = {},
  activeExperiments = {},
}: {
  getMenuProps: (props?: unknown) => object;
  isOpen: boolean;
  searchSuggestions: SearchItem[];
  loadingSuggestions: boolean;
  highlightedIndex: number;
  getItemProps: (props?: unknown) => object;
  keyword: string;
  menuStyle?: any;
  track?: (...args: any[]) => void;
  activeExperiments?: any;
}) {
  const { t } = useTranslation(
    "ui-components-website\\find-classes\\SearchSuggestionsMenu"
  );
  const isMobile = useIsMobile();

  useTrackSuggestions(
    track,
    keyword,
    searchSuggestions,
    isOpen,
    activeExperiments
  );

  searchSuggestions = isMobile
    ? searchSuggestions.slice(0, 8)
    : searchSuggestions;

  return (
    <StyledUnorderedList
      {...getMenuProps({
        sx: (theme: Theme) => ({
          /* eslint-disable i18next/no-literal-string */
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
          borderRadius: 8,
          padding: isOpen && searchSuggestions.length > 0 ? 8 : 0,
          position: "absolute",
          listStyle: "none",
          cursor: "pointer",
          background: "white",
          zIndex: 1,
          display: isOpen ? "block" : "none",
          width: 421,
          [theme.breakpoints.up("md").replace("min-width", "minWidth")]: {
            width: 495,
          },
          [theme.breakpoints.down("md").replace("max-width", "maxWidth")]: {
            width: 291,
          },
          ...menuStyle,
        }),
        /* eslint-enable i18next/no-literal-string */
      })}
    >
      {loadingSuggestions && (
        <Box>
          <Loading sx={{ paddingY: "200px" }} />
        </Box>
      )}
      {!loadingSuggestions &&
        searchSuggestions.map((suggestion, index) => (
          <TopicSuggestionListItem
            index={index}
            key={`${suggestion.type}-${suggestion.uid}-${JSON.stringify(
              suggestion.searchFilters
            )}`}
            searchSuggestions={searchSuggestions}
            highlightedIndex={highlightedIndex}
            getItemProps={getItemProps}
            suggestion={suggestion}
          >
            {suggestion.type === "keyword" && (
              <Typography variant="inherit">
                {/* eslint-disable react/no-unescaped-entities */}
                <Trans t={t}>
                  <Typography
                    variant="inherit"
                    sx={{
                      fontWeight: 500,
                    }}
                    component="span"
                  >
                    "{{ keyword }}"
                  </Typography>{" "}
                  in all classes
                </Trans>
                {/* eslint-enable react/no-unescaped-entities */}
              </Typography>
            )}
            {(suggestion.type === "topic" || suggestion.type === "popular") && (
              <TopicSearchSuggestion topic={suggestion} />
            )}
            {suggestion.type === "placeholder" && (
              <PlaceholderSearchSuggestion topic={suggestion} />
            )}
            {suggestion.type === "teacher" && (
              <TeacherSearchSuggestion teacher={suggestion} />
            )}
            {suggestion.type === "saved" && (
              <SearchSuggestionItemWithFilters
                suggestion={suggestion}
                icon={faBookmark}
              />
            )}
            {suggestion.type === "recent" && (
              <SearchSuggestionItemWithFilters
                suggestion={suggestion}
                icon={faClock}
              />
            )}
          </TopicSuggestionListItem>
        ))}
    </StyledUnorderedList>
  );
}

function PlaceholderSearchSuggestion({ topic }: { topic: SearchItem }) {
  return (
    <Typography
      variant="inherit"
      sx={{
        fontWeight: 500,
      }}
    >
      <TopicIcon />
      <Typography variant="inherit" component="span">
        {topic.name}
      </Typography>
    </Typography>
  );
}

function TopicSearchSuggestion({ topic }: { topic: SearchItem }) {
  return (
    <Typography
      variant="inherit"
      sx={{
        fontWeight: 500,
      }}
    >
      <TopicIcon topicCategory={topic.category} />
      <Typography variant="inherit" component="span">
        {topic.name}
      </Typography>
    </Typography>
  );
}

function TopicSuggestionListItem({
  index,
  searchSuggestions,
  highlightedIndex,
  getItemProps,
  suggestion,
  children,
}: PropsWithChildren<{
  index: number;
  searchSuggestions: SearchItem[];
  highlightedIndex: number;
  getItemProps: (props: unknown) => unknown;
  suggestion: SearchItem;
}>) {
  return (
    <Box
      as="li"
      sx={(theme: Theme) => ({
        display: "flex",
        minHeight: 60,
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 8px",
        gap: "16px",
        fontSize: 20,
        borderBottom:
          index + 1 !== searchSuggestions.length
            ? "1px solid rgba(221, 223, 227, 1)"
            : "initial",
        backgroundColor: highlightedIndex === index ? "#E6E9FF" : "initial",
        [theme.breakpoints.down("md")]: {
          minHeight: 40,
          padding: "12px 8px",
          gap: "12px",
          fontSize: "1.6rem",
        },
      })}
      {...(getItemProps({ item: suggestion, index }) as object)}
    >
      {children}
    </Box>
  );
}

function TeacherSearchSuggestion({ teacher }: { teacher: SearchItem }) {
  return (
    <Typography
      variant="inherit"
      sx={{
        fontWeight: 500,
        alignItems: "left",
        overflow: "hidden",
        display: "flex",
        width: "100%",
      }}
    >
      <UserHeadshotImage
        user={teacher}
        size={32}
        sx={{ marginRight: "16px" }}
      />
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {teacher.name}
      </Box>
    </Typography>
  );
}

function SearchSuggestionItemWithFilters({
  suggestion,
  icon,
}: {
  suggestion: SearchItem;
  icon: IconDefinition;
}) {
  let filterPills = searchSuggestionFilterPills(suggestion);
  return (
    <Box
      sx={(theme: Theme) => ({
        fontWeight: 500,
        color: "common.black",
        display: "flex",
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
      })}
    >
      <Icon
        fixedWidth
        icon={icon}
        sx={{
          fontSize: "24px",
          marginRight: "16px",
          paddingTop: "2px",
        }}
      />
      <Typography variant="inherit" component="span">
        {suggestion.name}
      </Typography>
      <Box
        sx={(theme: Theme) => ({
          marginLeft: "30px",
          marginTop: "-3px",
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          [theme.breakpoints.down("md")]: {
            marginLeft: 0,
            marginTop: 10,
          },
        })}
      >
        {filterPills}
      </Box>
    </Box>
  );
}

function searchSuggestionFilterPills(suggestion: SearchItem) {
  const filters = suggestion.searchFilters;

  if (!filters) {
    return null;
  }

  let cleanedFilters = Object.entries(filters)
    .filter(([_key, value]) => value && value != "undefined")
    .filter(
      ([key, _value]) =>
        !["__typename", "q", "order", "userUid", "userName"].includes(key)
    );

  // merge start time and end time into a single filter
  const timeRangeString = timeRangeToString(
    filters.startAfterTime,
    filters.endByTime
  );
  if (timeRangeString) {
    cleanedFilters.push(["time", timeRangeString]);
    cleanedFilters = cleanedFilters.filter(
      ([key, _value]) => key !== "startAfterTime" && key !== "endByTime"
    );
  }

  // merge group filters and class type filters
  const delivery = new Set(filters.delivery?.split(","));
  if (filters.capacityMax === 1) {
    // 1-1 course
    cleanedFilters = cleanedFilters.filter(
      ([key, _value]) => key !== "capacityMax"
    );
    cleanedFilters.push(["type", "Tutoring & Private Lessons"]);
  } else if (
    filters.capacityMin &&
    filters.capacityMin > 1 &&
    delivery.has("Semester course") &&
    delivery.has("Short course")
  ) {
    // semester courses
    cleanedFilters = cleanedFilters.filter(
      ([key, _value]) => key !== "capacityMin" && key !== "delivery"
    );
    cleanedFilters.push(["type", "Full Courses"]);
  } else if (
    filters.capacityMin &&
    filters.capacityMin > 1 &&
    delivery.has("Ongoing class") &&
    delivery.has("Camp") &&
    delivery.has("Group")
  ) {
    // group classes
    cleanedFilters = cleanedFilters.filter(
      ([key, _value]) => key !== "capacityMin" && key !== "delivery"
    );
    cleanedFilters.push(["type", "Enrichment & Clubs"]);
  } else if (
    filters.capacityMin &&
    filters.capacityMin > 1 &&
    delivery.has("One-time class")
  ) {
    // one time activities
    cleanedFilters = cleanedFilters.filter(
      ([key, _value]) => key !== "capacityMin" && key !== "delivery"
    );
    cleanedFilters.push(["type", "One-Time Activities"]);
  }

  if (!suggestion.name && cleanedFilters.length === 0) {
    return null;
  }

  const filterPills = cleanedFilters.map(([filterName, filterValue]) => {
    const filterPillContent = getFilterDisplayString({
      filterName,
      filterValue: (filterValue as string) || "",
    });

    if (!filterPillContent) {
      return null;
    }

    return (
      <Box
        sx={(theme: Theme) => ({
          backgroundColor: "neutral.50",
          borderRadius: "50px",
          padding: "3px 14px",
          display: "inline-block",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        })}
        key={filterName}
      >
        <Typography
          variant="inherit"
          sx={{
            fontSize: 16,
            lineHeight: "24px",
            color: "common.black",
          }}
        >
          {filterPillContent}
        </Typography>
      </Box>
    );
  });

  return filterPills;
}
