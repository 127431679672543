import { Box, Theme, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { dayjs } from "@outschool/time";
import { useIsMounted } from "@outschool/ui-utils";
import React, { useEffect, useState } from "react";

function CountdownValue({
  value,
  hideDays,
  primaryColor,
  secondaryColor,
}: {
  value: number;
  hideDays?: boolean;
  primaryColor: string;
  secondaryColor: string;
}) {
  return (
    <Box
      flex
      sx={(theme: Theme) => ({
        justifyContent: "center",
        alignItems: "center",
        width: hideDays ? "29%" : "22%",
        height: "2rem",
        borderRadius: "3px",
        backgroundColor: secondaryColor,
        marginTop: "0.25rem",
        [theme.breakpoints.up("sm")]: {
          height: "2.5rem",
          borderRadius: "5px",
        },
      })}
    >
      <Typography
        variant="subtitle1"
        sx={(theme: Theme) => ({
          color: primaryColor,
          fontSize: "1.2rem",
          [theme.breakpoints.up("sm")]: {
            fontSize: "1.5rem",
          },
        })}
      >
        {value < 0 ? 0 : value}
      </Typography>
    </Box>
  );
}

function CountdownText({
  text,
  hideDays,
  primaryColor,
  secondaryColor,
}: {
  text: string;
  hideDays?: boolean;
  primaryColor: string;
  secondaryColor: string;
}) {
  return (
    <Box
      flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: hideDays ? "29%" : "22%",
        backgroundColour: primaryColor,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={(theme: Theme) => ({
          width: "100%",
          textAlign: "center",
          color: secondaryColor,
          fontSize: "0.9rem",
          [theme.breakpoints.up("sm")]: {
            fontSize: "1.1rem",
          },
        })}
      >
        {text}
      </Typography>
    </Box>
  );
}
type CountdownTime = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type CountdownTimerProps = {
  deadline: dayjs.Dayjs;
  primaryColor: string;
  secondaryColor: string;
  hideDays?: boolean;
};

/*  NOTE: Pass deadline as a fixed point in time, EX: dayjs("June 14, 2024 8:57:00"). */
export default function CountdownTimer({
  deadline,
  ...props
}: CountdownTimerProps) {
  const { t } = useTranslation("ui-components-website\\CountdownTimer");
  const [timeLeft, setTimeLeft] = useState<CountdownTime | null>(null);
  const isMounted = useIsMounted();
  const { hideDays } = props;

  useEffect(() => {
    function formatTimeLeft() {
      const now = dayjs();
      let timeLeftInMilliseconds = deadline.diff(now, "milliseconds", true);

      const millisecondsInADay = 86400000;
      const millisecondsInAnHour = 3600000;
      const millisecondsInAMinute = 60000;
      const millisecondsInASecond = 1000;

      let days = 0;
      if (!hideDays) {
        days = Math.floor(timeLeftInMilliseconds / millisecondsInADay);
        timeLeftInMilliseconds = timeLeftInMilliseconds % millisecondsInADay;
      }

      const hours = Math.floor(timeLeftInMilliseconds / millisecondsInAnHour);
      timeLeftInMilliseconds = timeLeftInMilliseconds % millisecondsInAnHour;

      const minutes = Math.floor(
        timeLeftInMilliseconds / millisecondsInAMinute
      );
      timeLeftInMilliseconds = timeLeftInMilliseconds % millisecondsInAMinute;

      const seconds = Math.floor(
        timeLeftInMilliseconds / millisecondsInASecond
      );

      return {
        days,
        hours,
        minutes,
        seconds,
      };
    }
    setTimeLeft(formatTimeLeft());

    const intervalId = setInterval(() => {
      setTimeLeft(() => {
        if (dayjs().isSameOrAfter(deadline)) {
          clearInterval(intervalId);
          return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
        }
        return formatTimeLeft();
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [deadline, hideDays]);

  if (timeLeft === null || !isMounted) {
    return null;
  }
  return (
    <Box
      sx={(theme: Theme) => ({
        minWidth: props.hideDays ? 65 : 85,
        backgroundColor: props.primaryColor,
        [theme.breakpoints.up("sm")]: {
          minWidth: props.hideDays ? 115 : 160,
          height: 42,
        },
      })}
    >
      <Box
        flex
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {!props.hideDays && <CountdownValue value={timeLeft.days} {...props} />}
        <CountdownValue value={timeLeft.hours} {...props} />
        <CountdownValue value={timeLeft.minutes} {...props} />
        <CountdownValue value={timeLeft.seconds} {...props} />
      </Box>

      <Box
        flex
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {!props.hideDays && <CountdownText text={t("Days")} {...props} />}
        <CountdownText text={t("Hrs")} {...props} />
        <CountdownText text={t("Min")} {...props} />
        <CountdownText text={t("Sec")} {...props} />
      </Box>
    </Box>
  );
}
