import {
  centsPerLearnerPerMeeting,
  centsPerLearnerPerWeek,
} from "@outschool/business-rules";
import { Activity, CurrencyCode } from "@outschool/gql-backend-generated";
import { useCallback } from "react";

import { BASE_CURRENCY, COUNTRIES_INFO } from "../constants";
import { shouldChargeInLocalCurrency } from "../utils/currency";
import * as Pricing from "./Pricing";
import { useConvertToUserDefaultCurrency } from "./useConvertToUserDefaultCurrency";
import { useCurrencyLocalization, useLocale, useTranslation } from "..";

export function useLocalPriceDisplay() {
  const { t } = useTranslation("localization\\hooks\\useLocalPriceDisplay");
  const locale = useLocale();
  const { countryCode, isLoading: localizationIsLoading } =
    useCurrencyLocalization();
  const {
    code: currencyCode = null,
    symbol: currencySymbol = null,
    disclaimer: currencyDisclaimer = null,
  } = COUNTRIES_INFO[countryCode]?.currency || {};
  const currencyDisclaimerDisplayed = currencyCode
    ? shouldChargeInLocalCurrency(currencyCode)
      ? null
      : currencyDisclaimer
    : null;

  const {
    convertCents,
    convertDollars,
    loading: exchangeRateLoading,
  } = useConvertToUserDefaultCurrency(currencyCode as CurrencyCode);

  const isLoading = localizationIsLoading || exchangeRateLoading;

  const freeOfChargeText = t("Free");

  return {
    currencyCode,
    currencySymbol,
    currencyDisclaimer: currencyDisclaimerDisplayed,
    convertCents,
    isBaseCurrency: !currencyCode || currencyCode === BASE_CURRENCY.code,
    renderCurrencyString: useCallback(
      ({
        priceInCents,
        useFree = false,
        showCurrencyCode = true,
        displayNoCents = false,
        roundUp,
      }: {
        priceInCents: number;
        useFree?: boolean;
        showCurrencyCode?: boolean;
        displayNoCents?: boolean;
        roundUp?: boolean;
      }): string => {
        if (isLoading || !currencyCode) {
          return "";
        }
        const convertedCents = convertCents(priceInCents);
        return Pricing.renderCurrencyString({
          priceInCents: convertedCents,
          useFree,
          freeOfChargeText,
          showCurrencyCode,
          displayNoCents,
          currencyCode,
          roundUp,
          locale,
        });
      },
      [currencyCode, isLoading, convertCents, locale, freeOfChargeText]
    ),
    makePerMeetingPriceText: useCallback(
      (
        activity: Pricing.OngoingPricedActivity &
          Pick<Activity, "isClub" | "weekly_meetings" | "hasTeacherSchedule">
      ) => {
        if (isLoading || !currencyCode) {
          return "";
        }
        const priceInCurrency = centsPerLearnerPerMeeting(activity);
        const convertedCents = convertCents(priceInCurrency);

        return Pricing.makePerMeetingPriceText(
          convertedCents,
          currencyCode,
          true,
          locale
        );
      },
      [isLoading, convertCents, currencyCode, locale]
    ),
    makePerWeekPriceText: useCallback(
      (
        activity: Pricing.OngoingPricedActivity &
          Pick<Activity, "isClub" | "weekly_meetings" | "hasTeacherSchedule">
      ) => {
        if (isLoading || !currencyCode) {
          return "";
        }
        const priceInCurrency = centsPerLearnerPerWeek(activity);
        const convertedCents = convertCents(priceInCurrency);

        return Pricing.makePerMeetingPriceText(
          convertedCents,
          currencyCode,
          true,
          locale
        );
      },
      [isLoading, convertCents, currencyCode, locale]
    ),
    makePerStudentPriceText: useCallback(
      (
        activity: Pricing.OngoingPricedActivity &
          Pick<Activity, "isClub" | "weekly_meetings" | "hasTeacherSchedule">
      ) => {
        if (isLoading || !currencyCode) {
          return "";
        }
        return Pricing.makePerStudentPrice(
          activity,
          currencyCode,
          convertCents,
          locale
        );
      },
      [isLoading, currencyCode, convertCents, locale]
    ),
    displayPerStudentPrice: useCallback(
      (
        activity: Pricing.Activity_PricingFragment,
        section: Pricing.Section_PricingFragment | null,
        enrollment: Pricing.Enrollment_PricingFragment | null
      ) => {
        if (isLoading || !currencyCode) {
          return "";
        }
        return Pricing.displayPerStudentPrice(
          activity,
          section,
          enrollment,
          currencyCode,
          convertCents,
          true,
          locale
        );
      },
      [isLoading, currencyCode, convertCents, locale]
    ),
    displayPricePerMeeting: useCallback(
      (
        pricePerMeetingOption: Parameters<
          typeof Pricing.pricePerMeetingToString
        >[0],
        getTranslatedPricePerMeetingStrings: (
          pricePerMeetingMaxDisplay: string,
          pricePerMeetingMinDisplay: string
        ) => {
          pricePerMeetingMaxString: string;
          pricePerMeetingMinString: string;
          anyPriceString: string;
        }
      ) => {
        if (isLoading || !currencyCode) {
          return "";
        }

        return Pricing.pricePerMeetingToString({
          ...pricePerMeetingOption,
          currencyCode,
          getTranslatedPricePerMeetingStrings,
          convert: convertDollars,
          locale,
        });
      },
      [isLoading, currencyCode, convertDollars, locale]
    ),
    displayPriceMinMax: useCallback(
      (
        priceOption: Parameters<typeof Pricing.priceMinMaxToString>[0],
        getTranslatedPriceMinMaxStrings: (
          priceMaxDisplay: string,
          priceMinDisplay: string
        ) => {
          priceMaxString: string;
          priceMinString: string;
          anyPriceString: string;
        }
      ) => {
        if (isLoading || !currencyCode) {
          return "";
        }

        return Pricing.priceMinMaxToString({
          ...priceOption,
          currencyCode,
          getTranslatedPriceMinMaxStrings,
          convert: convertDollars,
          locale,
        });
      },
      [isLoading, currencyCode, convertDollars, locale]
    ),
  };
}
