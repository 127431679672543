import React, { useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import Cookies from "js-cookie";
import { dayjs } from "@outschool/time";
import CountdownTimer from "../CountdownTimer";
import { faX } from "@outschool/icons";
import { CanUserRedeemCouponQuery } from "@outschool/gql-frontend-generated";
import { gql } from "@outschool/ui-apollo";

const COOKIE_KEY_HIDE = "os-hide-promo-banner";
const COOKIE_KEY_APPLY = "os-apply-coupon";

export function TopBarPromoBanner({
  data,
  couponCode,
  showCountdown = false,
}: {
  data: CanUserRedeemCouponQuery;
  couponCode: string;
  showCountdown?: boolean;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Banners\\TopBarPromoBanner"
  );

  const [hideBanner, setHideBanner] = useState(
    Cookies.get(COOKIE_KEY_HIDE) === couponCode
  );
  const [couponApplied, setCouponApplied] = useState(false);

  const { expiresAt, userIsEligibleToApplyCoupon } = data.coupon;
  const canRedeemCoupon = userIsEligibleToApplyCoupon.isEligible;

  function applyCoupon() {
    Cookies.set(COOKIE_KEY_APPLY, couponCode, { expires: 1 }); // 1 day
    Cookies.set(COOKIE_KEY_HIDE, couponCode, { expires: 1 });
    setCouponApplied(true);
  }

  function hideBannerAndSetCookie() {
    Cookies.set(COOKIE_KEY_HIDE, couponCode, { expires: 7 });
    setHideBanner(true);
  }

  const { breakpoints } = useTheme();

  if (!canRedeemCoupon || hideBanner) {
    return null;
  }

  return (
    <Box
      flex
      sx={{
        backgroundColor: "primary.main",
        color: "grey.100",
        minHeight: "4.7rem",
        cursor: "pointer",
        gap: "1em",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 2s",
        padding: "10px 20px 10px 10px",
      }}
      onClick={applyCoupon}
    >
      <Box
        sx={{
          [breakpoints.down("sm")]: {
            display: "none",
          },
        }}
      >
        🎉
      </Box>
      <Typography variant="subtitle1" sx={{ textWrap: "pretty" }}>
        {couponApplied ? (
          <Trans t={t}>
            Coupon <b>{couponCode}</b> will be auto-applied at checkout!
          </Trans>
        ) : (
          <Trans t={t}>
            Get $20 off your first class! Click here or use code{" "}
            <b>{couponCode}</b> at checkout. Terms apply.
          </Trans>
        )}
      </Typography>
      {showCountdown && expiresAt && (
        <CountdownTimer
          deadline={dayjs(expiresAt)}
          primaryColor="primary.main"
          secondaryColor="grey.100"
          hideDays={true}
        />
      )}
      <IconButton
        icon={faX}
        variant="text"
        size="small"
        aria-label="Close promotional banner"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          color: "white",
          "&:hover": { backgroundColor: "unset !important" },
        }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          hideBannerAndSetCookie();
        }}
      />
    </Box>
  );
}

export const canUserRedeemCouponQuery = gql`
  query CanUserRedeemCoupon($couponUid: String!) {
    coupon(uid: $couponUid) {
      uid
      expiresAt
      userIsEligibleToApplyCoupon {
        isEligible
        reason
      }
    }
  }
`;
