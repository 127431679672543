import pkg from "../lib/pkg";
import { AnalyticsPluginError, logDebug } from "../lib/util";
import {
  AnalyticsMode,
  AnalyticsModeSource,
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import A8Plugin from "./A8";
import AdotOnePlugin from "./AdotOnePlugin";
import AwinPlugin from "./Awin";
import EnrichmentPlugin from "./Enrichment";
import FacebookPlugin from "./Facebook";
import KakaoPlugin from "./Kakao";
import NaverPlugin from "./Naver";
import SocialLadderPlugin from "./SocialLadder";

import type { IPContext } from "@outschool/iplookup-client";
import type {
  Analytics,
  Context,
  Plugin as PluginInterface,
} from "@segment/analytics-next";
import type { PageContext } from "../providers/PageContext";
import type { EventOptions } from "../types";

export interface AnalyticsPlugin extends PluginInterface {
  name: Integration;
  category: IntegrationCategory;
  type: AnalyticsPluginType;
  unload: PluginInterface["unload"];
  isLoadable: () => boolean;
}

export enum AnalyticsPluginType {
  before = "before",
  after = "after",
  destination = "destination",
  enrichment = "enrichment",
  utility = "utility",
}

export interface CreatePluginParams {
  analyticsMode: AnalyticsMode;
  analyticsModeSource: AnalyticsModeSource;
  analyticsModeSourceOverride: AnalyticsModeSource | null;
  locationInfo: IPContext;
  options: EventOptions;
  page: PageContext;
}

export function createPlugins({
  analyticsMode,
  analyticsModeSource,
  analyticsModeSourceOverride,
  locationInfo,
  options,
  page,
}: CreatePluginParams): Array<AnalyticsPlugin> {
  const { integrations } = options;

  const plugins: Array<AnalyticsPlugin> = [
    new EnrichmentPlugin({
      analyticsMode,
      analyticsModeSource,
      analyticsModeSourceOverride,
      locationInfo,
      page,
    }),
  ];

  if (!!integrations[Integration.Awin]) {
    plugins.push(new AwinPlugin());
  }

  if (!!integrations[Integration.Facebook]) {
    plugins.push(new FacebookPlugin());
  }

  if (!!integrations[Integration.Naver]) {
    plugins.push(new NaverPlugin());
  }

  if (!!integrations[Integration.Kakao]) {
    plugins.push(new KakaoPlugin());
  }

  if (!!integrations[Integration.AdotOne]) {
    plugins.push(new AdotOnePlugin());
  }

  if (!!integrations[Integration.A8]) {
    plugins.push(new A8Plugin());
  }

  if (!!integrations[Integration.SocialLadder]) {
    plugins.push(new SocialLadderPlugin());
  }

  plugins.forEach(plugin => {
    const { load, page, track, unload } = plugin;
    plugin.load = setLoadHook(plugin, load);
    plugin.unload = setUnloadHook(plugin, unload);
    plugin.page = setSendHook(plugin, page);
    plugin.track = setSendHook(plugin, track);
  });

  return plugins;
}

function setLoadHook(
  plugin: AnalyticsPlugin,
  load: AnalyticsPlugin["load"]
): AnalyticsPlugin["load"] {
  return async function loadHook(
    context: Context,
    analytics: Analytics
  ): Promise<void> {
    let retries = 0;
    while (true) {
      try {
        retries && logDebug("Retrying plugin:", plugin.name);

        await load.call(plugin, context, analytics);
        logDebug("Plugin:", `${plugin.name} loaded`);
        break;
      } catch (error) {
        logDebug("Failed to load plugin:", plugin.name);

        if (!!plugin.unload) {
          await plugin.unload(context, analytics);
        }

        if (retries == 2) {
          const err = new AnalyticsPluginError(
            plugin,
            "Failed to load after retries",
            error
          );
          pkg.onError(err);
          break;
        }

        retries++;
      }
    }
  };
}

function setUnloadHook(
  plugin: AnalyticsPlugin,
  unload: AnalyticsPlugin["unload"]
): AnalyticsPlugin["unload"] {
  if (!unload) {
    return;
  }

  return async function unloadHook(
    context: Context,
    analytics: Analytics
  ): Promise<void> {
    try {
      analytics.integrations[plugin.name] = undefined;
      await unload.call(plugin, context, analytics);
      logDebug("Plugin:", `${plugin.name} unloaded`);
    } catch (error) {
      const err = new AnalyticsPluginError(plugin, "Failed to unload", error);
      pkg.onError(err);
    }
  };
}

function setSendHook(
  plugin: AnalyticsPlugin,
  send: AnalyticsPlugin["track"] | AnalyticsPlugin["page"]
): AnalyticsPlugin["track"] | AnalyticsPlugin["page"] {
  if (!send) {
    return;
  }

  return async function sendHook(context: Context): Promise<Context> {
    try {
      let ctx: Context;

      if (send instanceof Promise) {
        ctx = await send.call(plugin, context);
      } else {
        ctx = send.call(plugin, context);
      }

      return ctx;
    } catch (error) {
      const err = new AnalyticsPluginError(
        plugin,
        "Failed to handle event",
        error
      );
      pkg.onError(err);
    }

    return context;
  };
}
