import {
  isOneOnOneFormat,
  perStudentPriceInCents,
} from "@outschool/business-rules";
import {
  Activity,
  CurrencyCode,
  Enrollment,
  Section,
  SectionChecklist,
} from "@outschool/gql-backend-generated";
import lodashCeil from "lodash/ceil";

import {
  BASE_LOCALE,
  CURRENCIES_INFO,
  CountryCode,
  I18nLocale,
  USD_STATIC_EXCHANGE_RATE,
  convertFromUSDCents,
  convertMinorToMajorDenomination,
} from "..";

export type OngoingPricedActivity = Pick<
  Activity,
  "is_ongoing_weekly" | "price_cents" | "duration_weeks"
>;

export type Activity_PricingFragment = OngoingPricedActivity &
  Pick<
    Activity,
    | "uid"
    | "size_max"
    | "size_min"
    | "weekly_meetings"
    | "isClub"
    | "hasTeacherSchedule"
    | "duration_weeks"
  >;

export type Enrollment_PricingFragment = Pick<Enrollment, "price_cents">;

export type Section_PricingFragment = Pick<
  Section,
  "uid" | "price_cents" | "start_time"
> & {
  checklist?: Partial<SectionChecklist>;
};

// TODO: Update with locale to correctly render where the '%' symbol lives. Pre-append/post-append..
export function renderPercentageDiscountString({
  percentOff,
}: {
  percentOff: number;
}): string {
  return `${percentOff}%`;
}

/**
 * Converts a price value (integer, in cents) to a human-readable string
 * like '$1' or '$12.99'.
 */
export function renderCurrencyString({
  priceInCents,
  useFree = false,
  freeOfChargeText,
  showCurrencyCode = true,
  displayNoCents = false,
  currencyCode,
  roundUp,
  locale = BASE_LOCALE,
  useBaseCurrency = false,
}: {
  priceInCents: number;
  useFree?: boolean;
  freeOfChargeText?: string;
  showCurrencyCode?: boolean;
  displayNoCents?: boolean;
  currencyCode?: CurrencyCode | null;
  roundUp?: boolean;
  locale?: I18nLocale;
  useBaseCurrency?: boolean;
}): string {
  // TODO: think about how we can default to USD, since the null value from
  // the database doesn't cause default parameters to be used.
  const currencyCodeWithFallback = currencyCode || CurrencyCode.Usd;

  if (priceInCents === 0) {
    if (useFree) {
      return freeOfChargeText ?? "Free";
    }

    return attachCurrencyDecoratorsToPrice({
      price: 0,
      showCurrencyCode,
      currencyCode: currencyCodeWithFallback,
      displayNoCents: true,
      locale,
      useBaseCurrency,
    });
  } else {
    return convertToCurrencyMainDenomination({
      amountInMinorCurrency: priceInCents,
      showCurrencyCode,
      currencyCode: currencyCodeWithFallback,
      displayNoCents,
      roundUp,
      locale,
      useBaseCurrency,
    });
  }
}

/**
 * Wrapper around renderCurrencyString. Converts an amount in USD cents to a given
 * currency and then renders that currency as a formatted, human-readable string.
 *
 * Usage note: if this function does not receive both a currency code and
 * exchange rate, it always default to USD and not perform any currency
 * conversion.
 *
 */
export function renderCurrencyStringWithCurrencyConversion({
  amountUSDCents,
  exchangeRate,
  displayNoCents = false,
  roundUp = false,
  currencyCode,
  showCurrencyCode = true,
  useFree = false,
  showUSD = false,
}: {
  amountUSDCents: number;
  exchangeRate?: number;
  displayNoCents?: boolean;
  roundUp?: boolean;
  currencyCode?: CurrencyCode;
  showCurrencyCode?: boolean;
  useFree?: boolean;
  showUSD?: boolean;
}): string {
  let priceToRender = amountUSDCents;

  if (showUSD) {
    exchangeRate = USD_STATIC_EXCHANGE_RATE;
    currencyCode = CurrencyCode.Usd;
  }

  if (exchangeRate && currencyCode) {
    priceToRender = convertFromUSDCents({
      amount: amountUSDCents,
      toExchangeRate: exchangeRate,
      toCurrencyCode: currencyCode,
    });
  }

  return renderCurrencyString({
    priceInCents: priceToRender,
    showCurrencyCode,
    useFree,
    // Ensure that we converted currency before passing currencyCode to
    // renderCurrencyString. Otherwise, default to USD.
    displayNoCents,
    roundUp,
    currencyCode:
      exchangeRate && currencyCode ? currencyCode : CurrencyCode.Usd,
  });
}

/**
 * Wrapper around attachCurrencyDecoratorsToPrice that accepts a minor denomination
 * (e.g. cents, pence). Converts to the main denomination of the currency and returns
 * it as a formatted string. For USD and most other currencies, this will be dollars or the
 * dollar equivalent (2 decimals or "minor units"). For the Yen and the Won,
 * there are 0 decimals, so the minor unit is also the main denomination.
 */
export function convertToCurrencyMainDenomination({
  amountInMinorCurrency,
  currencyCode,
  showCurrencyCode = true,
  displayNoCents = false,
  roundUp,
  locale = BASE_LOCALE,
  useBaseCurrency = false,
}: {
  amountInMinorCurrency: number;
  currencyCode: CurrencyCode;
  showCurrencyCode?: boolean;
  displayNoCents?: boolean;
  roundUp?: boolean;
  locale?: I18nLocale;
  useBaseCurrency?: boolean;
}): string {
  const priceInMajorUnits = convertMinorToMajorDenomination({
    amount: amountInMinorCurrency,
    currencyCode,
  });

  const priceHasNoCents = Math.floor(+priceInMajorUnits) === +priceInMajorUnits;
  const shouldDisplayNoCents = displayNoCents || priceHasNoCents;

  return attachCurrencyDecoratorsToPrice({
    price: priceInMajorUnits,
    showCurrencyCode,
    currencyCode,
    displayNoCents: shouldDisplayNoCents,
    roundUp,
    locale,
    useBaseCurrency,
  });
}

/**
 * Formats a given price with a currency symbol and a currency code (if
 * specified). For example: $100.30 USD or $100.30
 */
export function attachCurrencyDecoratorsToPrice({
  price,
  showCurrencyCode,
  currencyCode = CurrencyCode.Usd,
  displayNoCents,
  roundUp,
  locale = BASE_LOCALE,
  useBaseCurrency = false,
}: {
  price: number | string;
  showCurrencyCode: boolean;
  currencyCode?: CurrencyCode;
  displayNoCents?: boolean;
  roundUp?: boolean;
  locale?: I18nLocale;
  useBaseCurrency?: boolean;
}): string {
  /* TODO update to use "roundingIncrement" from Intl.NumberFormat v3 once it's released
   * https://github.com/tc39/proposal-intl-numberformat-v3#new-roundingprecision-options-ecma-402-286
   */
  const { precision } = CURRENCIES_INFO[currencyCode];

  if (roundUp) {
    price = lodashCeil(+price, precision - 2);
  }

  const fractionDigits = displayNoCents ? 0 : precision;

  const numberFormatProps = {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };

  const displayPrice = useBaseCurrency
    ? `${+price < 0 ? "-" : ""}${CountryCode.US}${Intl.NumberFormat(locale, {
        ...numberFormatProps,
        currencyDisplay: "narrowSymbol",
        signDisplay: "never",
      }).format(+price)}`
    : Intl.NumberFormat(locale, numberFormatProps).format(+price);

  return `${displayPrice}${showCurrencyCode ? ` ${currencyCode}` : ""}`;
}

export function makePerMeetingPriceText(
  pricePerMeetingMinor: number,
  currencyCode: CurrencyCode,
  roundUp: boolean = false,
  locale: I18nLocale = BASE_LOCALE
): string {
  const pricePerMeeting = parseFloat(
    convertMinorToMajorDenomination({
      amount: pricePerMeetingMinor,
      currencyCode,
    })
  );
  if (pricePerMeeting > 0 && pricePerMeeting < 1) {
    return `<${attachCurrencyDecoratorsToPrice({
      price: 1,
      showCurrencyCode: false,
      currencyCode,
      displayNoCents: true,
      locale,
    })}`;
  }

  return `${attachCurrencyDecoratorsToPrice({
    price: pricePerMeeting,
    showCurrencyCode: false,
    currencyCode,
    displayNoCents: true,
    roundUp,
    locale,
  })}`;
}

/**
 * Display the price in a human-readable form.
 */
export function displayPerStudentPrice(
  activity: OngoingPricedActivity &
    Pick<
      Activity,
      | "isClub"
      | "hasTeacherSchedule"
      | "size_max"
      | "duration_weeks"
      | "weekly_meetings"
    >,
  section: Section_PricingFragment | null,
  enrollment: Enrollment_PricingFragment | null,
  currencyCode: null | CurrencyCode = CurrencyCode.Usd,
  convert: (price: number) => number = price => price,
  roundUp?: boolean,
  locale: I18nLocale = BASE_LOCALE
): string {
  const price = convert(perStudentPriceInCents(activity, section, enrollment));

  if (price) {
    const durationSuffix = activity.isClub
      ? " per month"
      : !!activity.is_ongoing_weekly
      ? " per week"
      : isOneOnOneFormat(activity)
      ? " per meeting"
      : " total";

    return `${renderCurrencyString({
      priceInCents: price,
      showCurrencyCode: false,
      currencyCode,
      displayNoCents: roundUp,
      roundUp,
      locale,
    })}${durationSuffix}`;
  } else {
    return renderCurrencyString({ priceInCents: price, useFree: true, locale });
  }
}

export function makePerStudentPrice(
  activity: OngoingPricedActivity & Pick<Activity, "isClub">,
  currencyCode: null | CurrencyCode = CurrencyCode.Usd,
  convert: (price: number) => number = price => price,
  locale: I18nLocale = BASE_LOCALE
): string {
  const price = convert(perStudentPriceInCents(activity, null, null));

  if (price) {
    return renderCurrencyString({
      priceInCents: price,
      showCurrencyCode: false,
      currencyCode,
      displayNoCents: true,
      locale,
    });
  } else {
    return renderCurrencyString({ priceInCents: price, useFree: true, locale });
  }
}

export function pricePerMeetingToString({
  pricePerMeetingMax,
  pricePerMeetingMin,
  currencyCode,
  convert = amount => amount,
  locale = BASE_LOCALE,
  getTranslatedPricePerMeetingStrings,
}: {
  pricePerMeetingMax: number;
  pricePerMeetingMin: number;
  currencyCode: CurrencyCode;
  convert: (amount: number) => number | string;
  locale?: I18nLocale;
  getTranslatedPricePerMeetingStrings: (
    pricePerMeetingMaxDisplay: string,
    pricePerMeetingMinDisplay: string
  ) => {
    pricePerMeetingMaxString: string;
    pricePerMeetingMinString: string;
    anyPriceString: string;
  };
}) {
  const pricePerMeetingMaxDisplay = attachCurrencyDecoratorsToPrice({
    price: convert(pricePerMeetingMax),
    showCurrencyCode: false,
    currencyCode: currencyCode,
    displayNoCents: true,
    locale,
  });
  const pricePerMeetingMinDisplay = attachCurrencyDecoratorsToPrice({
    price: convert(pricePerMeetingMin),
    showCurrencyCode: false,
    currencyCode: currencyCode,
    displayNoCents: true,
    locale,
  });

  const { pricePerMeetingMaxString, pricePerMeetingMinString, anyPriceString } =
    getTranslatedPricePerMeetingStrings(
      pricePerMeetingMaxDisplay,
      pricePerMeetingMinDisplay
    );

  return pricePerMeetingMax
    ? pricePerMeetingMaxString
    : pricePerMeetingMin
    ? pricePerMeetingMinString
    : anyPriceString;
}

export function priceMinMaxToString({
  priceMax,
  priceMin,
  currencyCode,
  convert = amount => amount,
  locale = BASE_LOCALE,
  getTranslatedPriceMinMaxStrings,
}: {
  priceMax: number;
  priceMin: number;
  currencyCode: CurrencyCode;
  convert: (amount: number) => number | string;
  locale?: I18nLocale;
  getTranslatedPriceMinMaxStrings: (
    priceMaxDisplay: string,
    priceMinDisplay: string
  ) => {
    priceMaxString: string;
    priceMinString: string;
    anyPriceString: string;
  };
}) {
  const priceMaxDisplay = attachCurrencyDecoratorsToPrice({
    price: convert(priceMax),
    showCurrencyCode: false,
    currencyCode: currencyCode,
    displayNoCents: true,
    locale,
  });
  const priceMinDisplay = attachCurrencyDecoratorsToPrice({
    price: convert(priceMin),
    showCurrencyCode: false,
    currencyCode: currencyCode,
    displayNoCents: true,
    locale,
  });

  const { priceMaxString, priceMinString, anyPriceString } =
    getTranslatedPriceMinMaxStrings(priceMaxDisplay, priceMinDisplay);

  return priceMax ? priceMaxString : priceMin ? priceMinString : anyPriceString;
}
