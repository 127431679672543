import { ActivitiesRow, SectionsRow } from "@outschool/db-queries";
import {
  BASE_LOCALE,
  I18nLocale,
  SUPPORTED_LOCALES,
} from "@outschool/localization";

import { BaseRoutes, makeUrlFunction } from "./baseRoutes";
import {
  PathFromActivityParams,
  createPathWithActivity,
} from "./canonicalRoutes";
import { makeUrlFromPathFn, pathToUrl } from "./config";
import {
  appleAuthRedirectPath,
  clubPath,
  clubPostCommentPath,
  clubPostPath,
  facebookAuthRedirectPath,
  googleAuthRedirectPath,
  oidcAuthLoginRedirectPath,
  oidcAuthLoginRedirectToProviderPath,
  recordingPath,
} from "./shared";
import {
  ActivitySlugProps,
  RouteConfigOptions,
  UrlParams,
  activitySlug,
  addParamsToUrl,
  getWebsiteHostForEnv,
  makeLocalePrefix,
} from "./utils";

/**
 * Provides paths and URLs to resources in the main app.
 *
 * We are abandoning this OO pattern in favor of stand-alone functions to
 * support tree-shaking. New functions do not need to be added to the class.
 *
 */
export class WebsiteRoutes extends BaseRoutes {
  constructor(config: RouteConfigOptions) {
    super(getWebsiteHostForEnv(config.appHostName), config.locale);
  }

  public static clubPath = clubPath;
  public clubPath = clubPath;
  public clubUrl = makeUrlFunction(clubPath);

  public static clubPostPath = clubPostPath;
  public clubPostPath = clubPostPath;
  public clubPostUrl = makeUrlFunction(clubPostPath);
  public static singlePostPath = singlePostPath;
  public singlePostPath = singlePostPath;
  public singlePostUrl = makeUrlFunction(singlePostPath);
  public static clubPostCommentPath = clubPostCommentPath;
  public clubPostCommentPath = clubPostCommentPath;
  public clubPostCommentUrl = makeUrlFunction(clubPostCommentPath);
  public static learnerProfilePath = learnerProfilePath;
  public learnerProfilePath = learnerProfilePath;
  public learnerProfileUrl = makeUrlFunction(learnerProfilePath);
  public static userAdminPath = userAdminPath;
  public userAdminPath = userAdminPath;
  public userAdminUrl = makeUrlFunction(userAdminPath);
  public static giftCardAdminPath = giftCardAdminPath;
  public giftCardAdminPath = giftCardAdminPath;
  public giftCardAdminUrl = makeUrlFunction(giftCardAdminPath);
  public static userConversationsPath = userConversationsPath;
  public userConversationsPath = userConversationsPath;
  public userConversationsUrl = makeUrlFunction(userConversationsPath);
  public static sectionLearnerPath = sectionLearnerPath;
  public sectionLearnerPath = sectionLearnerPath;
  public sectionLearnerUrl = makeUrlFunction(sectionLearnerPath);
  public static emailSettingsPath = emailSettingsPath;
  public emailSettingsPath = emailSettingsPath;
  public emailSettingsUrl = makeUrlFunction(emailSettingsPath);
  public static emailPreferenceUnsubscribePath = emailPreferenceUnsubscribePath;
  public emailPreferenceUnsubscribePath = emailPreferenceUnsubscribePath;
  public emailPreferenceUnsubscribeUrl = makeUrlFunction(
    emailPreferenceUnsubscribePath
  );
  public static activityPath = activityPath;
  public activityPath = activityPath;
  public activityUrl = makeUrlFunction(activityPath);
  public static sectionFeedbackPath = sectionFeedbackPath;
  public sectionFeedbackPath = sectionFeedbackPath;
  public sectionFeedbackUrl = makeUrlFunction(sectionFeedbackPath);
  public static classroomPath = classroomPath;
  public classroomPath = classroomPath;
  public classroomUrl = makeUrlFunction(classroomPath);
  public static sectionOrActivityPath = sectionOrActivityPath;
  public sectionOrActivityPath = sectionOrActivityPath;
  public sectionOrActivityUrl = makeUrlFunction(sectionOrActivityPath);
  public static emailSectionOrActivityPath = emailSectionOrActivityPath;
  public emailSectionOrActivityPath = emailSectionOrActivityPath;
  public emailSectionOrActivityUrl = makeUrlFunction(
    emailSectionOrActivityPath
  );
  public static enrolledPath = enrolledPath;
  public enrolledPath = enrolledPath;
  public enrolledUrl = makeUrlFunction(enrolledPath);

  public static safetyPath = safetyPath;
  public safetyPath = safetyPath;
  public safetyUrl = makeUrlFunction(safetyPath);
  public static enrollmentConfirmationPath = enrollmentConfirmationPath;
  public enrollmentConfirmationPath = enrollmentConfirmationPath;
  public enrollmentConfirmationUrl = makeUrlFunction(
    enrollmentConfirmationPath
  );
  public static bulkOrderConfirmationPath = bulkOrderConfirmationPath;
  public bulkOrderConfirmationPath = bulkOrderConfirmationPath;
  public bulkOrderConfirmationUrl = makeUrlFunction(bulkOrderConfirmationPath);
  public static receiptPath = receiptPath;
  public receiptPath = receiptPath;
  public receiptUrl = makeUrlFunction(receiptPath);
  public static invitePath = invitePath;
  public invitePath = invitePath;
  public inviteUrl = makeUrlFunction(invitePath);
  public static sectionEmailSharePath = sectionEmailSharePath;
  public sectionEmailSharePath = sectionEmailSharePath;
  public sectionEmailShareUrl = makeUrlFunction(sectionEmailSharePath);
  public static parentSettingsPath = parentSettingsPath;
  public parentSettingsPath = parentSettingsPath;
  public parentSettingsUrl = makeUrlFunction(parentSettingsPath);
  public static parentManagePaymentMethodPath = parentManagePaymentMethodPath;
  public parentManagePaymentMethodPath = parentManagePaymentMethodPath;
  public parentManagePaymentMethodUrl = makeUrlFunction(
    parentManagePaymentMethodPath
  );
  public static leaderPath = leaderPath;
  public leaderPath = leaderPath;
  public leaderUrl = makeUrlFunction(leaderPath);
  public static learnerSchedulePath = learnerSchedulePath;
  public learnerSchedulePath = learnerSchedulePath;
  public learnerScheduleUrl = makeUrlFunction(learnerSchedulePath);
  public static sectionLearnersPath = sectionLearnersPath;
  public sectionLearnersPath = sectionLearnersPath;
  public sectionLearnersUrl = makeUrlFunction(sectionLearnersPath);
  public static recordingPath = recordingPath;
  public recordingPath = recordingPath;
  public recordingUrl = makeUrlFunction(recordingPath);
  public static changePinPath = changePinPath;
  public changePinPath = changePinPath;
  public changePinUrl = makeUrlFunction(changePinPath);
  public static redirectAfterLoginPath = redirectAfterLoginPath;
  public redirectAfterLoginPath = redirectAfterLoginPath;
  public redirectAfterLoginUrl = makeUrlFunction(redirectAfterLoginPath);
  public static learnerThreadPathV2 = learnerThreadPathV2;
  public learnerThreadPathV2 = learnerThreadPathV2;
  public learnerThreadUrlV2 = makeUrlFunction(learnerThreadPathV2);
  public static adultThreadPathV2 = adultThreadPathV2;
  public adultThreadPathV2 = adultThreadPathV2;
  public adultThreadUrlV2 = makeUrlFunction(adultThreadPathV2);
  public static parentAuthCodeTransferPath = parentAuthCodeTransferPath;
  public parentAuthCodeTransferPath = parentAuthCodeTransferPath;
  public parentAuthCodeTransferUrl = makeUrlFunction(
    parentAuthCodeTransferPath
  );
  public static sectionEnrollPath = sectionEnrollPath;
  public sectionEnrollPath = sectionEnrollPath;
  public sectionEnrollUrl = makeUrlFunction(sectionEnrollPath);
  public static sectionCharterSchoolEnrollPath = sectionCharterSchoolEnrollPath;
  public sectionCharterSchoolEnrollPath = sectionCharterSchoolEnrollPath;
  public sectionCharterSchoolEnrollUrl = makeUrlFunction(
    sectionCharterSchoolEnrollPath
  );
  public static sellerOrgAgreementForUserPath = sellerOrgAgreementForUserPath;
  public sellerOrgAgreementForUserPath = sellerOrgAgreementForUserPath;
  public sellerOrgAgreementForUserUrl = makeUrlFunction(
    sellerOrgAgreementForUserPath
  );

  public static intercomPopUpPath = intercomPopUpPath;
  public intercomPopUpPath = intercomPopUpPath;
  public intercomPopUpUrl = makeUrlFunction(intercomPopUpPath);

  public static redirectToLearnerPath = redirectToLearnerPath;
  public redirectToLearnerPath = redirectToLearnerPath;
  public redirectToLearnerUrl = makeUrlFunction(redirectToLearnerPath);

  public static sectionMeetingsPath = sectionMeetingsPath;
  public sectionMeetingsPath = sectionMeetingsPath;
  public sectionMeetingsUrl = makeUrlFunction(sectionMeetingsPath);
  public static sectionNoteMakerPath = sectionNoteMakerPath;
  public sectionNoteMakerPath = sectionNoteMakerPath;
  public sectionNoteMakerUrl = makeUrlFunction(sectionNoteMakerPath);

  public static sectionEditPath = sectionEditPath;
  public sectionEditPath = sectionEditPath;
  public sectionEditUrl = makeUrlFunction(sectionEditPath);

  public static sectionAssignmentsPath = sectionAssignmentsPath;
  public sectionAssignmentsPath = sectionAssignmentsPath;
  public sectionAssignmentsUrl = makeUrlFunction(sectionAssignmentsPath);

  public static selfPacedTermsAndConditionsPath =
    selfPacedTermsAndConditionsPath;
  public selfPacedTermsAndConditionsPath = selfPacedTermsAndConditionsPath;
}

export { clubPath, clubPostCommentPath, clubPostPath } from "./shared";

export function singlePostPath(sectionUid: string, classPostUid: string) {
  return `/classroom/${sectionUid}/posts/${classPostUid}`;
}

export interface ObjectWithUid {
  uid: string;
}

export function learnerProfilePath(learner: ObjectWithUid) {
  return `/learner/${learner.uid}/profile`;
}

export function userAdminPath(uid: string) {
  return `/admin/users/${uid}`;
}

export function giftCardAdminPath(userUid: string) {
  return `${userAdminPath(userUid)}/giftcards`;
}

/** @param isEducator Is the current user a teacher? */
export function userConversationsPath(
  isEducator: boolean,
  uid: string,
  limit?: number
) {
  // Don't check the educator mode feature flag here since this is only used for
  // back-end services and emails where we want to go ahead and give teachers
  // the /teach/conversations routes.
  const base = isEducator ? `/teach/conversations` : `/conversations`;
  return addParamsToUrl(base, {
    limit: limit?.toString(),
    filterUids: `p_${uid}`,
  });
}

export function sectionLearnerPath(
  section: ObjectWithUid,
  learnerOrEnrollment: ObjectWithUid
) {
  return `/sections/${section.uid}/learners/${learnerOrEnrollment.uid}`;
}

export function emailSettingsPath(token: string, locale?: string | null) {
  return (
    makeLocalePrefix(locale) +
    addParamsToUrl("/account/email-settings", { token })
  );
}

export function emailPreferenceUnsubscribePath({
  name,
  token,
  locale,
}: {
  name: string;
  token: string;
  locale?: string | null;
}) {
  return (
    makeLocalePrefix(locale) +
    addParamsToUrl("/account/email-settings", {
      name,
      token,
    })
  );
}

export function activityPath(
  activity: ActivitySlugProps,
  noTitleInSlug = false,
  locale?: string | null
) {
  return (
    makeLocalePrefix(locale) +
    (activity.is_club || activity.isClub ? "/groups/" : "/classes/") +
    activitySlug(activity, noTitleInSlug)
  );
}

export function sectionFeedbackPath(
  activity: ActivitiesRow,
  sectionUid: string
) {
  return activityPath(activity, true) + `/feedback?sectionUid=${sectionUid}`;
}

export function classroomPath(
  sectionUid: string,
  learnerUid?: string | null,
  locale?: string | null
) {
  return (
    makeLocalePrefix(locale) +
    addParamsToUrl(`/classroom/${sectionUid}`, {
      learnerUid,
    })
  );
}

export function shoppingCartPath() {
  return "/cart";
}

export function shoppingCartCheckoutPath() {
  return "/cart/checkout";
}

export function sectionOrActivityPath(
  activity: ActivitySlugProps,
  sectionUid?: string,
  noTitleInSlug: boolean = false,
  showDetails: boolean = false
) {
  let path =
    (activity.is_club || activity.isClub ? "/groups/" : "/classes/") +
    activitySlug(activity, noTitleInSlug);

  path = addParamsToUrl(path, {
    ...(sectionUid && { sectionUid }),
    ...(showDetails && { showDetails }),
  });

  return path;
}

export const sectionOrActivityUrl = makeUrlFromPathFn(sectionOrActivityPath);

export function emailSectionOrActivityPath(
  activity: ActivitiesRow,
  sectionUid: string
) {
  return sectionOrActivityPath(activity, sectionUid, true);
}

export function enrolledPath(sectionUid: string, locale?: string | null) {
  return makeLocalePrefix(locale) + `/enrolled/${sectionUid}`;
}

export function safetyPath(locale?: string | null) {
  return makeLocalePrefix(locale) + "/trust";
}

export function enrollmentConfirmationPath(sectionUid: string) {
  return `/enrollment-confirmation/${sectionUid}`;
}

export function bulkOrderConfirmationPath(orderSlugId: string) {
  return `/bulk-order-confirmation/${orderSlugId}`;
}

export function receiptPath(orderSlugId: string) {
  return `/account/payments/${orderSlugId}`;
}

export function invitePath() {
  return "/invite";
}

export interface SectionEmailSharePathParams {
  activity: ActivitiesRow;
  section?: SectionsRow;
  isEnrolled?: boolean;
  senderName?: string;
  campaignPrefix?: string;
  locale?: string | null;
}

export function sectionEmailSharePath({
  activity,
  section,
  isEnrolled,
  senderName,
  campaignPrefix,
  locale,
}: SectionEmailSharePathParams) {
  const query: UrlParams = {};
  if (section) {
    Object.assign(query, {
      sectionUid: section && section.uid,
      showDetails: true,
    });
  }
  if (isEnrolled) {
    query.isEnrolled = "true";
  }
  if (senderName) {
    query.senderName = senderName;
  }
  if (campaignPrefix) {
    query.campaignPrefix = campaignPrefix;
  }
  return addParamsToUrl(
    `${activityPath(activity, false, locale)}/email`,
    query
  );
}

export function parentSettingsPath() {
  return "/account/parent";
}

export function parentManagePaymentMethodPath() {
  return "/account/parent?managePayments=true";
}

export function leaderPath(leaderUid: string, leaderLink?: string | null) {
  return "/teachers/" + (leaderLink || leaderUid);
}

export const leaderUrl = makeUrlFromPathFn(leaderPath);

export function learnerSchedulePath(view?: string, tab?: string) {
  const path = "/learn/schedule";
  return addParamsToUrl(path, {
    ...(view && { view }),
    ...(tab && { tab }),
  });
}

export function sectionLearnersPath(section: ObjectWithUid) {
  return `/sections/${section.uid}/learners`;
}

export function changePinPath() {
  return "/organization/change-pin";
}

export function redirectAfterLoginPath() {
  return `/accounts/redirect-after-login`;
}

export const redirectAfterLoginUrl = makeUrlFromPathFn(redirectAfterLoginPath);

export function subscriptionsPath() {
  return `/unlimited`;
}

export const subscriptionsUrl = makeUrlFromPathFn(subscriptionsPath);

export const afterStripeCheckoutPath = (checkoutSessionId: string) =>
  `/after-stripe-checkout/${checkoutSessionId}`;
export const afterStripeCheckoutUrl = makeUrlFromPathFn(
  afterStripeCheckoutPath
);

export function stripeCheckoutPath() {
  return `/create-checkout-session`;
}

export function stripeBillingPath() {
  return `/create-billing-session`;
}

export const stripeCheckoutUrl = makeUrlFromPathFn(stripeCheckoutPath);

export function loginPath(
  ignorePostLogin: boolean = false,
  overridePostLoginPath?: string
) {
  const loginPath = "/users/login";
  if (ignorePostLogin) {
    return loginPath;
  }

  if (typeof window === "undefined") {
    return loginPath;
  }

  const postLoginPath =
    overridePostLoginPath ||
    window?.location?.pathname + (window?.location?.search || "");

  if (!postLoginPath) {
    return loginPath;
  }

  const [basePathname] = postLoginPath.split("/").filter(Boolean);
  // Check if route contains a locale. If it does, drop the locale and use the rest of the pathname.
  const pathToCompare = (SUPPORTED_LOCALES as string[]).includes(basePathname)
    ? // Fall back to root path if we end up with an empty string
      postLoginPath.slice(`/${basePathname}`.length) || "/"
    : postLoginPath;

  if (pathToCompare === "blank" || pathToCompare === "/") {
    return loginPath;
  }

  const pathToCompareUrl = new URL(pathToCompare, window?.location?.origin);

  const pathToCompareUrlSearch = pathToCompareUrl.search || "";

  const params = new URLSearchParams(pathToCompareUrlSearch);

  // we perform this check to avoid nesting postLoginPath params on the login path
  // if we are not on the login page and if postLoginPath is missing
  // then set postLoginPath
  if (loginPath !== pathToCompare && !params.has("postLoginPath")) {
    params.set(
      "postLoginPath",
      pathToCompareUrl.pathname + pathToCompareUrlSearch
    );
  }

  if (params.get("signup")) {
    // Make sure we aren't showing the sign up modal on the login page.
    params.delete("signup");
  }

  // if there is a nested postLoginPath remove parameter
  if (params.get("postLoginPath")?.includes("postLoginPath")) {
    params.delete("postLoginPath");
  }
  const paramString = params.toString();
  const searchParams = Boolean(paramString) ? `?${paramString}` : "";

  return loginPath + searchParams;
}

export const loginUrl = makeUrlFromPathFn(loginPath);

export function cookiePolicyPath() {
  return "/privacy#cookies";
}

export function categorySitemapPath(pageNumber?: string | number | undefined) {
  const base = "/categories";
  if (!pageNumber) {
    return base;
  } else {
    return `${base}/${pageNumber}`;
  }
}

export const categorySitemapUrl = makeUrlFromPathFn(categorySitemapPath);

export function newClassesOnOutschoolPath(week?: string) {
  return `/new-classes-on-outschool${week ? `/${week}` : ""}`;
}

export const newClassesOnOutschoolUrl = makeUrlFromPathFn(
  newClassesOnOutschoolPath
);

export function requiredProfessionalLearningPath() {
  return "/teach/required-professional-learning";
}

export function giftCardLandingPath() {
  return "/giftcard";
}

export function teachPath() {
  return "/teach";
}

export function forSellerOrgPath() {
  return "/for-organizations";
}

export function sellerOrgPath() {
  return "/organization";
}

export function sellerOrgAgreementForUserPath(userUid: string) {
  return `/organization/${userUid}/agreement`;
}

export function aboutPath() {
  return "/about";
}

export function tutoringPath() {
  return "/tutoring";
}

export function onboardingQuizPath(locale?: I18nLocale | null) {
  return makeLocalePrefix(locale) + "/onboarding-quiz";
}

export function pitchPagePath(filters?: Record<string, any>) {
  if (filters) {
    return addParamsToUrl("/pitch-page", filters);
  } else {
    return "/pitch-page";
  }
}
export function careersPath() {
  return "/careers";
}

export function blogPath(articleSlug = "") {
  return `/blog/${articleSlug}`;
}

export function pagesPath(slug: string) {
  return `/pages/${slug}`;
}

export function articlesPath(slug: string) {
  return `/articles/${slug}`;
}

export function bundleEditPath(bundleUid: string) {
  return `/teach/class-links/${bundleUid}/edit`;
}

export function bundleSharePath(bundleUid: string) {
  return `/class-links/${bundleUid}`;
}

export const LEARNER_CONVERSATIONS_PATH_SUFFIX = "/learners";
const learnerConversationsBasePath = `/teach/conversations${LEARNER_CONVERSATIONS_PATH_SUFFIX}`;
export const ADULT_CONVERSATIONS_PATH_SUFFIX = "/conversations";
export const THREADS_PATH = "/threads";

const adultConversationsBasePath = (flag: boolean) =>
  flag
    ? `/teach${ADULT_CONVERSATIONS_PATH_SUFFIX}`
    : ADULT_CONVERSATIONS_PATH_SUFFIX;

export enum UserMode {
  Educator = "educator",
  Parent = "parent",
}

export function adultThreadPathV2(
  userMode: UserMode,
  threadUid: string,
  queryParams?: UrlParams
) {
  // Since TS allows any type to be coerced into a template string, make sure
  // this value is a string first.
  const base: string = adultConversationsBasePath(
    userMode === UserMode.Educator
  );
  const url = `${base}${THREADS_PATH}/${threadUid}`;
  return !queryParams ? url : addParamsToUrl(url, queryParams);
}

export function learnerThreadPathV2(
  threadUid: string,
  queryParams?: UrlParams
) {
  const url = `${learnerConversationsBasePath}${THREADS_PATH}/${threadUid}`;
  return !queryParams ? url : addParamsToUrl(url, queryParams);
}

export function parentAuthCodeTransferPath(
  transferToken: string,
  redirect?: string,
  isParentLogin = false
) {
  const queryParams: UrlParams = { redirect };
  if (isParentLogin) {
    queryParams.pl = "t";
  }
  return addParamsToUrl("/parent-auth-code/" + transferToken, queryParams);
}

export function intercomPopUpPath() {
  return "/?intercom=true";
}

export function redirectToLearnerPath({
  learnerAppUrl,
  enrollmentUid,
}: {
  learnerAppUrl: string;
  enrollmentUid: string;
}) {
  return addParamsToUrl(`/redirect-to-learner`, {
    learnerAppUrl,
    enrollmentUid,
  });
}

export function privacyPath(locale?: string | null) {
  return makeLocalePrefix(locale) + "/privacy";
}

export function privacyPathCA(locale?: string | null) {
  return makeLocalePrefix(locale) + "/pages/privacy-CA";
}

export function privacyPathCOPPA(locale?: string | null) {
  return makeLocalePrefix(locale) + "/pages/privacy-COPPA";
}

export function privacyPathToolsAndSites(locale?: string | null) {
  return makeLocalePrefix(locale) + "/pages/privacy-toolsandsites";
}

export function termsPath() {
  return "/terms";
}

export function personalizedAdSettingsPath() {
  return "/personalized-ad-settings";
}

export function joinTeacherAccountPath() {
  return "/join/teacher/account";
}

export function joinParentAccountPath(params?: any) {
  return addParamsToUrl("/join/account", params);
}

export function teacherClassLinksPath() {
  return "/teach/class-links";
}

export function teacherSchedulePath(date?: any) {
  return addParamsToUrl("/teach/schedule", { date });
}

export function teacherDashboardPath() {
  return "/teach/dashboard";
}

export function leadActivitiesPath() {
  return "/teach";
}

export function browseRootPath(locale?: I18nLocale) {
  return `${makeLocalePrefix(locale)}/`;
}

export function isHomePage(pathname: string) {
  return pathname === "/";
}

export function parentPath(parentUid: string) {
  return "/parents/" + parentUid;
}

export function parentProfilePath(parentUid: string) {
  return "/parents/" + parentUid;
}

export function parentPrivatePath({
  uid,
  profile_link_uid,
}: {
  uid: string;
  profile_link_uid: string;
}) {
  return `/parents/${uid}/${profile_link_uid}`;
}

export function publicProfileLink({
  uid,
  profile_link_uid,
}: {
  uid?: string | null;
  profile_link_uid?: string | null;
}) {
  if (!uid) {
    return "";
  }

  if (!profile_link_uid) {
    return parentPath(uid);
  }
  return parentPrivatePath({ uid, profile_link_uid });
}

export function shareLinkWithParentProfile(
  user: { uid: string; slug_id: string },
  params: any
) {
  const url = pathToUrl(parentPath(user.uid));
  return addParamsToUrl(url, {
    signup: true,
    usid: user ? user.slug_id : undefined,
    ...params,
  });
}

export function findClassesPath(filters?: UrlParams) {
  if (filters) {
    return addParamsToUrl("/find-classes", filters);
  } else {
    return "/find-classes";
  }
}

export function joinParentAppleAccountPath() {
  return "/join/account/apple";
}

export function changePasswordPath(locale?: string | null) {
  return makeLocalePrefix(locale) + "/users/change_password";
}

export function northCarolinaEsaFundsLoggedInBannerCtaPath() {
  return "https://outschool.com/articles/use-north-carolina-education-student-account?utm_campaign=ESA-Logged-in-Banner ";
}

export function esaFundsLoggedInBannerCtaPath() {
  return "https://info.outschool.com/esa?utm_campaign=ESA&utm_source=Outschool&utm_medium=Product&utm_content=Logged-in-banner";
}

export function esaFundsLoggedOutBannerCtaPath() {
  return "https://info.outschool.com/esa?utm_campaign=ESA&utm_source=Outschool&utm_medium=Product&utm_content=Logged-out-banner";
}

export function esaFundsFooterPath() {
  return "https://info.outschool.com/esa?utm_campaign=ESA&utm_source=Outschool&utm_medium=Product&utm_content=footer";
}

export function gameConLoggedInBannerCtaPath() {
  return "https://info.outschool.com/gamecon-2022?utm_medium=productl&utm_source=logged_in_homepage&utm_campaign=gamecon";
}

export function fallCouponBannerCtaPath() {
  return "https://info.outschool.com/b2g1?utm_medium=&utm_source=&utm_campaign=b2g1productbanner";
}

export function outschoolOrgPath() {
  return "https://www.outschool.org";
}

export function downloadOnAppStoreFooterPath() {
  return "https://apps.apple.com/app/apple-store/id1594191740?pt=123314890&ct=product-footer&mt=8 ";
}

export function downloadOnGooglePlayFooterPath() {
  return "https://play.google.com/store/apps/details?id=com.outschool.learner";
}

export function stateScholarshipFundsInfoPath() {
  return "https://teach.outschool.com/handbook/help-families-pay-for-your-classes-with-state-scholarship-funds/";
}

export const changePasswordUrl = makeUrlFromPathFn(changePasswordPath);

export function searchPath(filters?: any) {
  if (filters) {
    return addParamsToUrl("/search", filters);
  } else {
    return "/search";
  }
}

export const searchUrl = makeUrlFromPathFn(searchPath);

export function magnetPagePath(slug: string): string {
  return "/articles/" + slug;
}

export const magnetPageUrl = makeUrlFromPathFn(magnetPagePath);

export function sectionEnrollPath(
  activity: any,
  section: any,
  isAutoScheduled: boolean = false
) {
  const basePath = createPathWithActivity(activity);
  return addParamsToUrl(`${basePath}/enroll`, {
    sectionUid: section.uid ?? section,
    ...(isAutoScheduled && { isAutoScheduled }),
  });
}
export function sectionWaitlistPath(
  activity: any,
  section: any,
  isAutoScheduled: boolean = false
) {
  const basePath = createPathWithActivity(activity);
  return addParamsToUrl(`${basePath}/waitlist`, {
    sectionUid: section.uid ?? section,
    ...(isAutoScheduled && { isAutoScheduled }),
  });
}

export function selfPacedEnrollPath(activity: any) {
  const basePath = createPathWithActivity(activity);
  return addParamsToUrl(`${basePath}/enroll`, {
    selfPaced: true,
  });
}

export function oneOnOneEnrollPath({
  activity,
  startTime,
  enrollmentUid,
  orgTeacherUid,
  isPrepayMeetings,
}: {
  activity: PathFromActivityParams;
  startTime: Date;
  enrollmentUid?: string;
  orgTeacherUid?: string;
  isPrepayMeetings?: boolean;
}) {
  const basePath = createPathWithActivity(activity);
  return addParamsToUrl(`${basePath}/enroll`, {
    startTime: startTime.toISOString(),
    enrollmentUid,
    orgTeacherUid,
    isPrepayMeetings,
  });
}

export const oneOnOneEnrollUrl = makeUrlFromPathFn(oneOnOneEnrollPath);

export function sectionCharterSchoolEnrollPath(activity: any, section: any) {
  const basePath = createPathWithActivity(activity);
  return `${basePath}/charter-enroll?sectionUid=${section.uid || section}`;
}

export const googleAuthRedirectUrl = makeUrlFromPathFn(
  googleAuthRedirectPath,
  BASE_LOCALE
);

export const facebookAuthRedirectUrl = makeUrlFromPathFn(
  facebookAuthRedirectPath,
  BASE_LOCALE
);

export const appleAuthRedirectUrl = makeUrlFromPathFn(
  appleAuthRedirectPath,
  BASE_LOCALE
);

export function firebaseAppleAuthRedirectUrl() {
  return "https://outschool-mobile.firebaseapp.com/__/auth/handler";
}

export const oidcAuthLoginRedirectUrl = makeUrlFromPathFn(
  oidcAuthLoginRedirectPath,
  BASE_LOCALE
);

export const oidcAuthLoginRedirectToProviderUrl = makeUrlFromPathFn(
  oidcAuthLoginRedirectToProviderPath,
  BASE_LOCALE
);

export function joinParentFacebookAccountPath() {
  return "/join/account/facebook";
}

export function joinParentGoogleAccountPath() {
  return "/join/account/google";
}

export function activitySchedulePath(activity: string | ObjectWithUid) {
  const activityValue = typeof activity === "string" ? activity : activity.uid;
  return `/classes/${activityValue}/schedule`;
}

export function activityPostsPath(activity: string | ObjectWithUid) {
  const activityValue = typeof activity === "string" ? activity : activity.uid;
  return `/classes/${activityValue}/posts`;
}

export function activityAvailabilityPath(activity: string | ObjectWithUid) {
  const activityValue = typeof activity === "string" ? activity : activity.uid;
  return `/classes/${activityValue}/availability`;
}

export function classLinkEditPath(classLinkUid: string) {
  return `/teach/class-links/${classLinkUid}/edit`;
}

export function classLinkCreatePath() {
  return `/class-links/create`;
}

export function sellerOrgClassLinksPath() {
  return "/organization/class-links";
}

export function orgTeacherAvailabilityPath() {
  return `/org-teacher/availability`;
}

export const orgTeacherAvailabilityUrl = makeUrlFromPathFn(
  orgTeacherAvailabilityPath
);

export const activityAvailabilityUrl = makeUrlFromPathFn(
  activityAvailabilityPath
);

export function clubManageListingPath(activity: ObjectWithUid) {
  return `/groups/${activity.uid}/manage`;
}

export function activityApprovalPath(
  activity: string | ObjectWithUid,
  mode?: any,
  subject?: any,
  includeFlex?: any
) {
  return addParamsToUrl(
    `/classes/${
      typeof activity === "string" ? activity : activity.uid
    }/approval`,
    {
      mode,
      subject,
      includeFlex,
    }
  );
}

export function sectionMeetingsPath(
  sectionUid: string,
  locale?: string | null
) {
  return makeLocalePrefix(locale) + `/sections/${sectionUid}/meetings`;
}

export function sectionEditPath(sectionUid: string, locale?: string | null) {
  return makeLocalePrefix(locale) + "/sections/" + sectionUid + "/edit";
}

export function sectionAssignmentsPath(
  sectionUid: string,
  queryParams?: {
    assignmentUid?: string;
    learnerUid?: string;
  }
) {
  return addParamsToUrl(
    `/sections/${sectionUid}/assignments`,
    queryParams || {}
  );
}

export function sectionNoteMakerPath(sectionUid: string) {
  return "/sections/" + sectionUid + "/notes";
}

export function sectionManageEnrollmentPath(sectionUid: string) {
  return "/sections/" + sectionUid + "/manage";
}

function savedClassesPathBuilder(
  url: string,
  order?: string,
  filters?: Record<any, any>
) {
  order = order === "saved" ? undefined : order;
  if (filters) {
    return addParamsToUrl(url, { ...filters, order });
  }
  return addParamsToUrl(url, { order });
}

export function publicSavedClassesPath(
  listUid: string,
  order?: string,
  filters?: Record<any, any>
) {
  return savedClassesPathBuilder(`/saved-classes/${listUid}`, order, filters);
}

export const publicSavedClassesUrl = (listUid: string) => {
  return pathToUrl(publicSavedClassesPath(listUid));
};

export function savedClassesPath(order?: string, filters?: Record<any, any>) {
  return savedClassesPathBuilder("/account/saved-classes", order, filters);
}

export const savedClassesUrl = (order?: string, filters?: Record<any, any>) => {
  return pathToUrl(savedClassesPath(order, filters));
};

export function selfPacedTermsAndConditionsPath() {
  return "/pages/teacher-terms-outschool-subscription";
}

export function learnerPlanPath(
  learner_uid?: string,
  plan_uid?: string,
  date?: string
) {
  return addParamsToUrl("/learn/planner", {
    learner_uid,
    plan_uid,
    date,
  });
}

export function learnerPlanSharePath() {
  return `/learn/planner/shared`;
}

export function tutorMatchPath(filters?: any) {
  if (filters) {
    return addParamsToUrl("/learn/tutor-match", filters);
  } else {
    return "/learn/tutor-match";
  }
}

export function learnerPlanCartPath(planUid: string) {
  return addParamsToUrl("/learn/planner/cart", { plan_uid: planUid });
}

export function classWalletSearchPath(esaState?: string) {
  if (!esaState) {
    return searchPath();
  }
  return searchPath({
    fundingPrograms: [esaState],
  });
}

export function savedSearchesPath() {
  return "/account/saved-searches";
}

export function followedTeachersPath() {
  return "/account/followed-teachers";
}

export const parentAuthCodeTransferUrl = makeUrlFromPathFn(
  parentAuthCodeTransferPath
);

export function websiteHomePath() {
  return "/";
}

export const websiteHomeUrl = makeUrlFromPathFn(websiteHomePath);
