import { CategoryPage } from "@outschool/gql-backend-generated";
import { I18nLocale, useLocale, useTranslation } from "@outschool/localization";
import { dayjs } from "@outschool/time";

export type CategoryPartial = Pick<CategoryPage, "nameTitleCased" | "slug"> &
  Partial<Omit<CategoryPage, "subcategories">> & {
    subcategories?: CategoryPartial[];
    includeOn?: string;
    excludeOn?: string;
    path?: string;
    showMore?: boolean; // To display the link "All `category` classes"
    can_link?: boolean;
    reloadDocument?: boolean;
  };

export function useAllNavbarCategories(): CategoryPartial[] {
  const { t } = useTranslation(
    "ui-components-website\\CategoryNavbar\\navbarCategories"
  );
  const locale = useLocale();

  const coreAcademicsCategory: CategoryPartial = {
    nameTitleCased: t("Core Academics"),
    slug: "courses",
    showMore: true,
    can_link: true,
    subcategories: [
      {
        nameTitleCased: t("Complete Curriculum"),
        slug: "full-curriculum",
        subcategories: [
          {
            nameTitleCased: t("Pre-K & Kindergarten"),
            slug: "pre-k-and-kindergarten-full-curriculum",
          },
          {
            nameTitleCased: t("1st Grade"),
            slug: "1st-grade-full-curriculum",
          },
          {
            nameTitleCased: t("2nd Grade"),
            slug: "2nd-grade-full-curriculum",
          },
          {
            nameTitleCased: t("3rd Grade"),
            slug: "3rd-grade-full-curriculum",
          },
          {
            nameTitleCased: t("4th Grade"),
            slug: "4th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("5th Grade"),
            slug: "5th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("6th Grade"),
            slug: "6th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("7th Grade"),
            slug: "7th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("8th Grade"),
            slug: "8th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("9th Grade"),
            slug: "9th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("10th Grade"),
            slug: "10th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("11th Grade"),
            slug: "11th-grade-full-curriculum",
          },
          {
            nameTitleCased: t("12th Grade"),
            slug: "12th-grade-full-curriculum",
          },
        ],
      },
      {
        nameTitleCased: t("English"),
        slug: "english",
        subcategories: [
          { nameTitleCased: t("Phonics"), slug: "phonics" },
          { nameTitleCased: t("Reading"), slug: "reading" },
          { nameTitleCased: t("Grammar"), slug: "grammar" },
          { nameTitleCased: t("Spelling"), slug: "spelling" },
          { nameTitleCased: t("Vocabulary"), slug: "vocabulary" },
          { nameTitleCased: t("Writing"), slug: "writing" },
          { nameTitleCased: t("Literature"), slug: "literature" },
          { nameTitleCased: t("Creative Writing"), slug: "creative-writing" },
          { nameTitleCased: t("Public Speaking"), slug: "public-speaking" },
          { nameTitleCased: t("Speech & Debate"), slug: "speech-and-debate" },
          { nameTitleCased: t("IEW"), slug: "iew" },
          { nameTitleCased: t("For Dyslexia"), slug: "dyslexia" },
        ],
      },
      {
        nameTitleCased: t("Mathematics"),
        slug: "math",
        subcategories: [
          {
            nameTitleCased: t("Counting & Numbers"),
            slug: "counting-and-numbers",
          },
          { nameTitleCased: t("Arithmetic"), slug: "arithmetic" },
          {
            nameTitleCased: t("Fractions & Percents"),
            slug: "fractions-and-decimals",
          },
          { nameTitleCased: t("Pre-Algebra"), slug: "pre-algebra" },
          { nameTitleCased: t("Algebra"), slug: "algebra" },
          { nameTitleCased: t("Geometry"), slug: "geometry" },
          { nameTitleCased: t("Algebra 2"), slug: "algebra-2" },
          { nameTitleCased: t("Pre-Calculus"), slug: "pre-calculus" },
          { nameTitleCased: t("Calculus"), slug: "calculus" },
          { nameTitleCased: t("Statistics"), slug: "statistics" },
          { nameTitleCased: t("Saxon Math"), slug: "about-saxon-math" },
          { nameTitleCased: t("Singapore Math"), slug: "singapore-math" },
          { nameTitleCased: t("Eureka Math"), slug: "eureka-math" },
        ],
      },
      {
        nameTitleCased: t("Science"),
        slug: "science",
        subcategories: [
          { nameTitleCased: t("Anatomy"), slug: "anatomy" },
          { nameTitleCased: t("Animals & Zoology"), slug: "animals" },
          { nameTitleCased: t("Biology"), slug: "biology" },
          { nameTitleCased: t("Chemistry"), slug: "chemistry" },
          {
            nameTitleCased: t("Environmental Science"),
            slug: "environmental-science",
          },
          { nameTitleCased: t("Food Science"), slug: "food-science" },
          { nameTitleCased: t("Forensic Science"), slug: "forensic-science" },
          {
            nameTitleCased: t("Geology & Earth Science"),
            slug: "about-geology",
          },
          { nameTitleCased: t("Medical Science"), slug: "medical-science" },
          { nameTitleCased: t("Physics"), slug: "physics" },
          { nameTitleCased: t("Space & Astronomy"), slug: "space-science" },
          { nameTitleCased: t("STEAM & STEM"), slug: "stem" },
          {
            nameTitleCased: t("Veterinary Science"),
            slug: "veterinary-science",
          },
        ],
      },
      {
        nameTitleCased: t("Social Studies"),
        slug: "social-studies",
        subcategories: [
          { nameTitleCased: t("Ancient History"), slug: "ancient-history" },
          { nameTitleCased: t("Economics"), slug: "economics" },
          { nameTitleCased: t("Geography"), slug: "geography" },
          {
            nameTitleCased: t("Government & Civics"),
            slug: "government-and-civics-courses",
          },
          { nameTitleCased: t("History"), slug: "history" },
          {
            nameTitleCased: t("Law & Criminal Justice"),
            slug: "law-and-criminal-justice",
          },
          { nameTitleCased: t("Linguistics"), slug: "linguistics" },
          { nameTitleCased: t("Philosophy"), slug: "philosophy" },
          { nameTitleCased: t("Psychology"), slug: "psychology" },
          { nameTitleCased: t("US History"), slug: "american-history" },
          { nameTitleCased: t("World History"), slug: "world-history" },
        ],
      },
      {
        nameTitleCased: t("Learning Methods"),
        slug: "learning-methods",
        subcategories: [
          { nameTitleCased: t("Gamified"), slug: "gamified-learning" },
          { nameTitleCased: t("Project-Based"), slug: "project-based" },
          { nameTitleCased: t("Hands-On"), slug: "hands-on" },
          { nameTitleCased: t("Discussion-Based"), slug: "discussion-based" },
          { nameTitleCased: t("Montessori"), slug: "montessori-education" },
          { nameTitleCased: t("Waldorf"), slug: "waldorf-education" },
        ],
      },
      {
        nameTitleCased: t("Learning Needs"),
        slug: "learning-needs",
        subcategories: [
          { nameTitleCased: t("Neurodiverse"), slug: "neurodiverse" },
          { nameTitleCased: t("ADHD"), slug: "adhd" },
          { nameTitleCased: t("Autism"), slug: "autism" },
          { nameTitleCased: t("Dyslexia"), slug: "dyslexia" },
          { nameTitleCased: t("Gifted"), slug: "gifted" },
        ],
      },
    ],
  };

  const electivesCategory: CategoryPartial = {
    nameTitleCased: t("Electives"),
    slug: "electives",
    showMore: true,
    can_link: true,
    subcategories: [
      {
        nameTitleCased: t("Arts"),
        slug: "arts-and-crafts",
        subcategories: [
          {
            nameTitleCased: t("Acting & Improv"),
            slug: "acting-and-improv",
          },
          { nameTitleCased: t("Animation"), slug: "animation" },
          {
            nameTitleCased: t("Architecture & Design"),
            slug: "architecture-and-design",
          },
          { nameTitleCased: t("Art History"), slug: "art-history" },
          { nameTitleCased: t("Crafts"), slug: "crafts" },
          { nameTitleCased: t("Dance"), slug: "dance" },
          { nameTitleCased: t("Digital Art"), slug: "digital-art" },
          { nameTitleCased: t("Drawing"), slug: "drawing" },
          { nameTitleCased: t("Fashion"), slug: "fashion" },
          { nameTitleCased: t("Film & Video"), slug: "film-and-video" },
          { nameTitleCased: t("Painting"), slug: "painting" },
          { nameTitleCased: t("Photography"), slug: "photography" },
        ],
      },
      {
        nameTitleCased: t("Music"),
        slug: "music",
        subcategories: [
          {
            nameTitleCased: t("Brass Instruments"),
            slug: "brass-instruments",
          },
          {
            nameTitleCased: t("Drums & Percussion"),
            slug: "drums-and-percussion",
          },
          {
            nameTitleCased: t("Guitar & Ukulele"),
            slug: "guitar-and-ukulele",
          },
          {
            nameTitleCased: t("Music Appreciation"),
            slug: "music-appreciation",
          },
          { nameTitleCased: t("Music Production"), slug: "music-production" },
          { nameTitleCased: t("Music History"), slug: "music-history" },
          { nameTitleCased: t("Music Theory"), slug: "music-theory" },
          { nameTitleCased: t("Piano & Keyboard"), slug: "piano" },
          { nameTitleCased: t("Singing"), slug: "singing" },
          { nameTitleCased: t("Violin, Viola, & Cello"), slug: "violin" },
          { nameTitleCased: t("Woodwind Instruments"), slug: "woodwind" },
        ],
      },
      {
        nameTitleCased: t("World Languages"),
        slug: "languages",
        subcategories: [
          { nameTitleCased: t("Sign Language"), slug: "sign-language" },
          { nameTitleCased: t("Arabic"), slug: "arabic" },
          { nameTitleCased: t("Chinese"), slug: "chinese" },
          { nameTitleCased: t("English (ESL/EFL)"), slug: "esl" },
          { nameTitleCased: t("French"), slug: "french" },
          { nameTitleCased: t("German"), slug: "german" },
          { nameTitleCased: t("Hebrew"), slug: "hebrew" },
          { nameTitleCased: t("Hindi"), slug: "hindi" },
          { nameTitleCased: t("Italian"), slug: "italian" },
          { nameTitleCased: t("Japanese"), slug: "japanese" },
          { nameTitleCased: t("Korean"), slug: "korean" },
          { nameTitleCased: t("Latin"), slug: "latin" },
          { nameTitleCased: t("Spanish"), slug: "spanish" },
        ],
      },
      {
        nameTitleCased: t("Coding & Tech"),
        slug: "coding-and-tech",
        subcategories: [
          {
            nameTitleCased: t("3D Modeling & Printing"),
            slug: "3d-modeling",
          },
          { nameTitleCased: t("Arduinos & Electronics"), slug: "arduino" },
          { nameTitleCased: t("Artificial Intelligence"), slug: "ai" },
          { nameTitleCased: t("Programming"), slug: "programming" },
          { nameTitleCased: t("Engineering"), slug: "engineering" },
          { nameTitleCased: t("Making Websites"), slug: "web-design" },
          { nameTitleCased: t("Making Apps"), slug: "app-design" },
          { nameTitleCased: t("Online Safety"), slug: "internet-safety" },
          { nameTitleCased: t("Robotics"), slug: "robotics" },
          { nameTitleCased: t("Software & Typing Skills"), slug: "typing" },
          {
            nameTitleCased: t("Video Game Design"),
            slug: "video-game-design",
          },
        ],
      },
      {
        nameTitleCased: t("Life Skills"),
        slug: "life-skills",
        subcategories: [
          { nameTitleCased: t("Babysitting"), slug: "babysitting" },
          { nameTitleCased: t("Career Prep"), slug: "career-prep" },
          {
            nameTitleCased: t("Cooking & Baking"),
            slug: "cooking-and-baking",
          },
          {
            nameTitleCased: t("Critical Thinking"),
            slug: "critical-thinking",
          },
          { nameTitleCased: t("Entrepreneurship"), slug: "entrepreneurship" },
          {
            nameTitleCased: t("Executive Function"),
            slug: "executive-function",
          },
          {
            nameTitleCased: t("Financial Literacy"),
            slug: "financial-literacy",
          },
          { nameTitleCased: t("Pet Training"), slug: "pet-training" },
          { nameTitleCased: t("Social Skills"), slug: "social-skills" },
          { nameTitleCased: t("Study Skills"), slug: "study-skills" },
        ],
      },
      {
        nameTitleCased: t("Health & Wellness"),
        slug: "health",
        subcategories: [
          { nameTitleCased: t("ADHD"), slug: "adhd" },
          { nameTitleCased: t("Autism"), slug: "autism" },
          { nameTitleCased: t("Fitness & Exercise"), slug: "fitness" },
          { nameTitleCased: t("Martial Arts"), slug: "martial-arts" },
          {
            nameTitleCased: t("Managing Emotions"),
            slug: "managing-emotions",
          },
          { nameTitleCased: t("Mental Health"), slug: "mental-health" },
          {
            nameTitleCased: t("Mindfulness & Meditation"),
            slug: "mindfulness",
          },
          { nameTitleCased: t("Nutrition"), slug: "nutrition" },
          { nameTitleCased: t("Sex Education"), slug: "sex-education" },
          { nameTitleCased: t("Sports"), slug: "sports" },
        ],
      },
    ],
  };

  const tutoringCategory = {
    nameTitleCased: t("Tutoring"),
    slug: "tutoring",
    showMore: true,
    can_link: true,
    subcategories: [
      {
        nameTitleCased: t("By Grade Level"),
        slug: "tutoring-by-grade-level",
        subcategories: [
          {
            nameTitleCased: t("Pre-K & Kindergarten"),
            slug: "pre-k-and-kindergarten-tutoring",
          },
          { nameTitleCased: t("1st Grade"), slug: "1st-grade-tutoring" },
          { nameTitleCased: t("2nd Grade"), slug: "2nd-grade-tutoring" },
          { nameTitleCased: t("3rd Grade"), slug: "3rd-grade-tutoring" },
          { nameTitleCased: t("4th Grade"), slug: "4th-grade-tutoring" },
          { nameTitleCased: t("5th Grade"), slug: "5th-grade-tutoring" },
          { nameTitleCased: t("6th Grade"), slug: "6th-grade-tutoring" },
          { nameTitleCased: t("7th Grade"), slug: "7th-grade-tutoring" },
          { nameTitleCased: t("8th Grade"), slug: "8th-grade-tutoring" },
          { nameTitleCased: t("9th Grade"), slug: "9th-grade-tutoring" },
          { nameTitleCased: t("10th Grade"), slug: "10th-grade-tutoring" },
          { nameTitleCased: t("11th Grade"), slug: "11th-grade-tutoring" },
          { nameTitleCased: t("12th Grade"), slug: "12th-grade-tutoring" },
        ],
      },
      {
        nameTitleCased: t("Academic Tutoring"),
        slug: "academic-tutoring",
        subcategories: [
          { nameTitleCased: t("Reading & Writing"), slug: "english-tutoring" },
          { nameTitleCased: t("Mathematics"), slug: "math-tutoring" },
          { nameTitleCased: t("Science"), slug: "science-tutoring" },
          { nameTitleCased: t("History"), slug: "history-tutoring" },
          {
            nameTitleCased: t("World Languages"),
            slug: "languages-tutoring",
          },
          { nameTitleCased: t("SAT/ACT"), slug: "sat-tutoring" },
          {
            nameTitleCased: t("AP Test Prep"),
            slug: "ap-test-prep-tutoring",
          },
        ],
      },
      {
        nameTitleCased: t("Private Lessons"),
        slug: "private-lessons",
        subcategories: [
          {
            nameTitleCased: t("Acting & Improv"),
            slug: "acting-and-improv-lessons",
          },
          { nameTitleCased: t("Chess"), slug: "chess-private-lessons" },
          { nameTitleCased: t("Coding"), slug: "coding-private-lessons" },
          { nameTitleCased: t("Dance"), slug: "private-dance-lessons" },
          { nameTitleCased: t("Music"), slug: "private-music-lessons" },
          {
            nameTitleCased: t("Painting & Drawing"),
            slug: "painting-and-drawing-private-lessons",
          },
          {
            nameTitleCased: t("Photography"),
            slug: "photography-private-lessons",
          },
          {
            nameTitleCased: t("Public Speaking"),
            slug: "public-speaking-private-lessons",
          },
        ],
      },
      {
        nameTitleCased: t("1-on-1 Coaching"),
        slug: "private-coaching",
        subcategories: [
          {
            nameTitleCased: t("Business & Finance"),
            slug: "business-and-finance-coaching",
          },
          {
            nameTitleCased: t("Career & Interview Prep"),
            slug: "career-and-interview-prep",
          },
          {
            nameTitleCased: t("Executive Function"),
            slug: "executive-function-tutoring",
          },
          {
            nameTitleCased: t("Social Skills"),
            slug: "social-skills-coaching",
          },
          {
            nameTitleCased: t("Study Skills"),
            slug: "study-skills-tutoring",
          },
          { nameTitleCased: t("Sports"), slug: "sports-coaching" },
        ],
      },
      {
        nameTitleCased: t("By Learning Need"),
        slug: "tutoring-by-learning-need",
        subcategories: [
          { nameTitleCased: t("ADHD"), slug: "adhd-tutoring" },
          { nameTitleCased: t("Autism"), slug: "autism-tutoring" },
          { nameTitleCased: t("Dyslexia"), slug: "dyslexia-tutoring" },
          { nameTitleCased: t("Gifted"), slug: "gifted-tutoring" },
        ],
      },
    ],
  };

  const justForFunCategory: CategoryPartial = {
    nameTitleCased: t("Just For Fun"),
    slug: "just-for-fun",
    showMore: true,
    can_link: true,
    subcategories: [
      {
        nameTitleCased: t("Learner Favorites"),
        slug: "learner-favorites",
        subcategories: [
          { nameTitleCased: t("Animals"), slug: "animals" },
          { nameTitleCased: t("Anime & Manga"), slug: "anime" },
          { nameTitleCased: t("Book Clubs"), slug: "book-clubs" },
          { nameTitleCased: t("Dinosaurs"), slug: "dinosaurs" },
          { nameTitleCased: t("Fan Clubs"), slug: "fan-clubs" },
          { nameTitleCased: t("Fashion"), slug: "fashion" },
          { nameTitleCased: t("Outer Space"), slug: "outer-space" },
          { nameTitleCased: t("Sports"), slug: "sports" },
          { nameTitleCased: t("Unicorns"), slug: "unicorns" },
          {
            nameTitleCased: t("Video Game Design"),
            slug: "video-game-design",
          },
        ],
      },
      {
        nameTitleCased: t("Games & Trivia"),
        slug: "games-and-trivia",
        subcategories: [
          { nameTitleCased: t("Video Game Clubs"), slug: "video-game-clubs" },
          { nameTitleCased: t("Role Play Games"), slug: "role-playing-games" },
          { nameTitleCased: t("Chess Clubs"), slug: "chess-clubs" },
          { nameTitleCased: t("Board & Card Games"), slug: "board-games" },
          { nameTitleCased: t("Escape Rooms"), slug: "escape-rooms" },
          { nameTitleCased: t("Mystery Games"), slug: "mystery-games" },
          { nameTitleCased: t("Math Games"), slug: "math-games" },
          { nameTitleCased: t("Trivia"), slug: "trivia" },
        ],
      },
      {
        nameTitleCased: t("For a Rainy Day"),
        slug: "for-a-rainy-day",
        subcategories: [
          { nameTitleCased: t("Seasonal Crafts"), slug: "seasonal-crafts" },
          { nameTitleCased: t("Sing-Alongs"), slug: "sing-along" },
          { nameTitleCased: t("Dance Parties"), slug: "dance-party" },
          {
            nameTitleCased: t("Learn Something Amazing"),
            slug: "learn-something-amazing",
          },
          {
            nameTitleCased: t("Science Experiments"),
            slug: "science-experimentation",
          },
          { nameTitleCased: t("Make a Treat"), slug: "make-a-treat" },
          { nameTitleCased: t("Travel the World"), slug: "travel-the-world" },
          { nameTitleCased: t("Play a Game"), slug: "games-and-activities" },
          { nameTitleCased: t("Laugh Together"), slug: "comedy" },
        ],
      },
    ],
  };

  const popularInKoreaCategory: CategoryPartial = {
    nameTitleCased: t("Popular"),
    slug: "popular-in-korea",
    showMore: true,
    can_link: true,
  };
  const popularInHongKongTaiwanCategory: CategoryPartial = {
    nameTitleCased: t("Popular"),
    slug: "popular-in-hong-kong-taiwan",
    showMore: true,
    can_link: true,
  };
  const newInEastAsiaCategory: CategoryPartial = {
    nameTitleCased: t("New classes"),
    slug: "new-in-ea",
    showMore: true,
    can_link: true,
  };
  const popularInJapanCategory: CategoryPartial = {
    nameTitleCased: t("Popular"),
    slug: "popular-in-japan",
    showMore: true,
    can_link: true,
  };

  const commonCategories = [
    tutoringCategory,
    coreAcademicsCategory,
    electivesCategory,
    justForFunCategory,
  ];

  switch (locale) {
    case I18nLocale.Ko:
      return [
        popularInKoreaCategory,
        ...commonCategories,
        newInEastAsiaCategory,
      ];
    case I18nLocale.ZhTw:
      return [
        popularInHongKongTaiwanCategory,
        ...commonCategories,
        newInEastAsiaCategory,
      ];
    case I18nLocale.Ja:
      return [
        popularInJapanCategory,
        ...commonCategories,
        newInEastAsiaCategory,
      ];
    default:
      return commonCategories;
  }
}

const dateFormat: string = "MM/DD/YY";

export function removeCategoriesByDate(category: CategoryPartial) {
  if (category.subcategories) {
    return {
      ...category,
      subcategories: filterByDate(category.subcategories),
    };
  } else {
    return category;
  }
}

export function filterByDate(categories: CategoryPartial[]) {
  const dayjsObj = dayjs();
  return categories!
    .filter(cat => {
      if (!cat.hasOwnProperty("includeOn")) {
        return true;
      }
      const includeDate = dayjs(cat.includeOn, dateFormat);
      return dayjsObj.isSameOrAfter(includeDate);
    })
    .filter(cat => {
      if (!cat.hasOwnProperty("excludeOn")) {
        return true;
      }
      const excludeDate = dayjs(cat.excludeOn, dateFormat);
      return dayjsObj.isSameOrBefore(excludeDate);
    })
    .map(cat => {
      const newCategory = { ...cat };
      delete newCategory.excludeOn;
      delete newCategory.includeOn;
      return newCategory;
    });
}

export default function useNavbarCategoriesFiltered() {
  const categories = useAllNavbarCategories();

  return filterByDate(categories).map(removeCategoriesByDate);
}
