import { Box, Icon } from "@outschool/backpack";
import { faApple } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import {
  appleAuthRedirectUrl,
  learnerAppleAuthRedirectUrl,
} from "@outschool/routes";
import { AppleButton } from "@outschool/ui-components-shared";
import { useComponentTrackingContext } from "@outschool/ui-legacy-component-library";
import { useScript } from "@outschool/ui-utils";
import React from "react";

const APPLE_SCRIPT_SRC: string =
  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

type AppleAuthOptions = {
  /** Client ID - eg: 'com.example.com' */
  clientId: string;
  /** Requested scopes, seperated by spaces - eg: 'email name' */
  scope: string;
  /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
  redirectURI: string;
  /** State string that is returned with the apple response */
  state?: string;
  /** Nonce */
  nonce?: string;
  /** Uses popup auth instead of redirection */
  usePopup?: boolean;
};

export type AppleAuthResponse = {
  authorization: {
    /** ID JWT */
    id_token: string;
    /** Grant code valid for 5m */
    code: string;
    /** State string passed to the request */
    state?: string;
  };
  /** Only provided by apple in the first request */
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (config: AppleAuthOptions) => void;
        signIn: () => Promise<AppleAuthResponse>;
      };
    };
  }
}

export type ContinueWithAppleProps = {
  clientId: string;
  isLearnerApp?: boolean;
  /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
  onSuccess: (response: AppleAuthResponse) => Promise<void>;
  /** Called upon signin error */
  onError: (error: any) => void;
  trackingName: string;
  onClick?: () => void;
};

export default function ContinueWithApple({
  clientId,
  isLearnerApp,
  onSuccess,
  onError,
  trackingName,
  onClick: onClickProp = () => {},
}: ContinueWithAppleProps) {
  const { t } = useTranslation("ui-auth\\src\\ContinueWithApple");
  const AppleID = useScript({
    src: APPLE_SCRIPT_SRC,
    variableName: "AppleID",
  });
  const [disabled, setDisabled] = React.useState(false);
  const track = useComponentTrackingContext();

  /** Button click handler */
  const handleClick = async (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!AppleID) {
      return;
    }
    try {
      onClickProp();

      setDisabled(true);
      AppleID.auth.init({
        clientId,
        redirectURI: isLearnerApp
          ? learnerAppleAuthRedirectUrl()
          : appleAuthRedirectUrl(),
        scope: "email name",
        usePopup: true,
      });
      const response = await AppleID.auth.signIn();
      await onSuccess(response);
    } catch (err) {
      if (
        err.error === "popup_closed_by_user" ||
        err.error === "user_cancelled_authorize"
      ) {
        // track popup closed and user cancelled authorize in Segment
        track(
          `apple_signin_${err.error}`,
          {},
          { integrations: { All: false } }
        );
      } else {
        onError(err);
      }
    } finally {
      setDisabled(false);
    }
  };

  return (
    <AppleButton
      aria-label={t("Continue with Apple ID")}
      onClick={handleClick}
      trackingName={trackingName}
      disabled={disabled || !AppleID}
      sx={{ padding: "0 !important", display: "flex" }}
    >
      <Box
        sx={{
          fontSize: "1.5em",
          padding: "10px",
          width: "44px",
        }}
      >
        <Icon
          icon={faApple}
          sx={{
            display: "block",
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: "12.5px",
          minWidth: "215px",
        }}
      >
        {t`Continue with Apple`}
      </Box>
    </AppleButton>
  );
}
