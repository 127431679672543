import React from "react";
import { Box, Icon } from "@outschool/backpack";
import { faEnvelope } from "@outschool/icons";
import { TrackedButton } from "@outschool/ui-components-shared";
import JoinParentAccountForm from "./JoinParentAccountForm";
import { useTranslation } from "@outschool/localization";

interface SimplifiedEmailSignupFormProps {
  trigger: () => void;
  showEmailSignupForm: boolean;
  setShowEmailSignupForm: (value: boolean) => void;
  handleSubmitClick: (loading: boolean) => void;
  setLoadForSimplifyEmailExp: (loading: boolean) => void;
  searchParams: { [key: string]: any };
  trackingUniqueId?: string;
  onUserLoggedIn: (sessionToken: string, refreshToken: string) => void;
  onUserRefresh: () => void;
}

/**
 * This const is used for the SimplifyEmailSignup experiment.
 * It conditionally shows the "Sign Up with Email" button or the email signup form.
 */
const SimplifiedEmailSignupForm = ({
  trigger,
  showEmailSignupForm,
  setShowEmailSignupForm,
  handleSubmitClick,
  setLoadForSimplifyEmailExp,
  searchParams,
  trackingUniqueId,
  onUserLoggedIn,
  onUserRefresh,
}: SimplifiedEmailSignupFormProps) => {
  const { t } = useTranslation("ui-auth\\src\\SimplifiedEmailSignupForm");

  return (
    <>
      {!showEmailSignupForm ? (
        <TrackedButton
          onClick={() => {
            setShowEmailSignupForm(true);
            trigger();
          }}
          data-test-id="signup-flow-link"
          trackingName="signup-with-email"
          trackingUniqueId={trackingUniqueId}
          sx={{
            padding: "0 !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "1.5em",
              padding: "10px",
              width: "44px",
            }}
          >
            <Icon
              icon={faEnvelope}
              sx={{
                display: "block",
              }}
            />
          </Box>
          <Box
            flex
            sx={{
              flex: 1,
              padding: "12.5px",
              minWidth: "215px",
              justifyContent: "center",
            }}
          >
            {t("Sign Up with Email")}
          </Box>
        </TrackedButton>
      ) : (
        <JoinParentAccountForm
          searchParams={searchParams}
          onSubmitClick={handleSubmitClick}
          setIsLoading={setLoadForSimplifyEmailExp}
          onUserLoggedIn={onUserLoggedIn}
          onUserRefresh={onUserRefresh}
        />
      )}
    </>
  );
};

export default SimplifiedEmailSignupForm;
